import React, { useEffect, useState } from "react";
// import ReactDOM from "react-dom";
import Tylo_icon from "../../assets/images/Tylo_logo.svg";
import Tracker_close from "../../assets/images/tracker-close.svg";
import Tracker from "../../assets/images/Customize your tracker.PNG";
import forYou from "../../assets/images/For_You.PNG";
// import Dropdown_open from "../../assets/images/Dropdown_open.svg" ;
// import Dropdown_closed from "../../assets/images/Dropdown_closed.svg";
import "../features/features.scss";
import { gapi } from "gapi-script";
import axios from "axios";
import {useNavigate } from "react-router-dom";
// import { setToken } from "../../services/userService";
import { createClient } from '@supabase/supabase-js';


function Track() {
    console.log('Track component rendering')
    // const [answerText, setAnswerText] = useState('');
    const googleProfileImageUrl = localStorage.getItem("googleProfileImageUrl");
    const navigate = useNavigate();
    
    const [showModal, setShowModal] = useState(false);
    const [showInstructions, setShowInstructions] = useState(false);

    /** Selection dropdoen */
    // const [selectedHeader, setSelectedHeader] = useState('');
    const [selectionCounts, setSelectionCounts] = useState({
        Research: 0,
        UseCase: 0,
        News: 0,
        Researcher: 0,
        Patent: 0,
        Organization: 0,
        Collaboration: 0
    });
    const [currentBody1, setCurrentBody1] = useState('');
    const [currentBody2, setCurrentBody2] = useState('');
    const [currentBody3, setCurrentBody3] = useState('');
    const [currentBody4, setCurrentBody4] = useState('');
    const [currentBody5, setCurrentBody5] = useState('');
    const [currentBody6, setCurrentBody6] = useState('');
    const [currentBody7, setCurrentBody7] = useState('');
    const [selectedOption1, setSelectedOption1] = useState('Research');
    const [selectedOption2, setSelectedOption2] = useState('Patent');
    const [selectedOption3, setSelectedOption3] = useState('Use case');
    const [selectedOption4, setSelectedOption4] = useState('Researcher');
    const [selectedOption5, setSelectedOption5] = useState('Organization');
    const [selectedOption6, setSelectedOption6] = useState('News');
    const [selectedOption7, setSelectedOption7] = useState('Collaboration');

    const [researchResults, setResearchResults] = useState([]);
    const [useCaseResults, setUseCaseResults] = useState([]);
    const [newsResults, setNewsResults] = useState([]); 
    const [rchResults, setRchResults] = useState([]); 
    const [orgResults, setOrgResults] = useState([]); 
    const [patentResults, setPatentResults] = useState([]); 
    const [collabResults, setCollabResults] = useState([]); 

    /*********  Tracker stuff ********************/
    const [researchHeader, setResearchHeader] = useState('Research');
    // const [patentHeader, setPatentHeader] = useState('');
    // const [patentBody, setPatentBody] = useState('');
    const [useCaseHeader, setUseCaseHeader] = useState('Use case');
    // const [researcherHeader, setResearcherHeader] = useState('');
    // const [organizationHeader, setOrganizationHeader] = useState('Organization');
    const [newsHeader, setNewsHeader] = useState('News');
    const [message, setMessage] = useState('');
    const [buttonText, setButtonText] = useState('Submit');
    const [buttonColor, setButtonColor] = useState('submit-button');


    // const [trackTitleResearch, setTrackTitleResearch] = useState('Title Here');
    const [bodyResearch, setBodyResearch] = useState('Body Here');
    // const [titleUseCase, setTitleUseCase] = useState('Title here');
    // const [bodyUseCase, setBodyUseCase] = useState('Body here');
    // const [titleNews,setTitleNews] = useState('Title Here');
    // const [bodyNews, setBodyNews] = useState('Body Here');
    // const [titleResearcher, setTitleResearcher] = useState('Title here');
    // const [bodyResearcher, setBodyResearcher] = useState('Body here');
    // const [titleOrganization, setTitleOrganization] = useState('Title here');
    // const [bodyOrganization, setBodyOrganization] = useState('Body here');
    // const [titlePatent, setTitlePatent] = useState('Title here');
    // const [bodyPatent, setBodyPatent] = useState('Body here');
    // const [titleCollab, setTitleCollab] = useState('Title here');
    // const [bodyCollab, setBodyCollab] = useState('Body here');

    // const [refPatent, setRefPatent] = useState('');
    // const [refUseCase, setRefUseCase] = useState('');
    // const [refNews, setRefNews] = useState('');
    // const [refOrg, setRefOrg] = useState('');
    // const [refCollab, setRefCollab] = useState('');

    const [showPreviousContent, setShowPreviousContent] = useState(false);
    const [savedResearchResults, setSavedResearchResults] = useState([]);
    const [savedUseCaseResults, setSavedUseCaseResults] = useState([]);
    const [savedResearcherResults, setSavedResearcherResults] = useState([]);
    const [savedNewsResults, setSavedNewsResults] = useState([]);
    const [savedPatentResults, setSavedPatentResults] = useState([]);
    const [savedOrgResults, setSavedOrgResults] = useState([]);
    const [savedCollabResults, setSavedCollabResults] = useState([]);
    const token = localStorage.getItem('token');
    const [credits, setCredits] = useState('');




    const handlePrivacyPolicyClick = () => {
        window.open(`${window.location.origin}/#privacy`, '_blank');
    };
    const handleTOUClick = () => {
        window.open(`${window.location.origin}/#termsofuse`, '_blank');
    }
    const navigateToSettings = () => {
        navigate('/settings'); // Navigate to /#features
    };

    const supabase = createClient('https://cyurqurlcxlyihpxzxyk.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImN5dXJxdXJsY3hseWlocHh6eHlrIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODgxNDEwNDQsImV4cCI6MjAwMzcxNzA0NH0.CdUPlN6gZQ6aA4kFiZuBXoAc4W_zXj4ywH0oaDAV70o')


    const formatDate = (date) => {
        const options = { day: '2-digit', month: '2-digit', year: '2-digit' };
        return new Date(date).toLocaleDateString('en-GB', options); // 'en-GB' uses day/month/year format, adjust if needed
    };
    
    /*******************************************  ********************/
    const updateCreditsDisplay = (credits) => {
        document.getElementById("creditDisplay").innerText = `Credits: ${credits}`;
    }
    
    const deductCredits = (amount) => {
        let currentCredits = credits;
        currentCredits = parseFloat((currentCredits - amount).toFixed(2)); 
        // localStorage.setItem("tyloCredits", currentCredits.toString());
        updateCreditsDisplay(currentCredits);
        updateBackendCredits(currentCredits);
        // console.log(credits)
    }

    const updateBackendCredits = async (newCredits) => {
        try {
            const token = localStorage.getItem('token'); // Assuming you're storing the token in localStorage
            const response = await axios.post("https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/updateCredits", {
                credits: newCredits, // Sending the new credits amount to the backend
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Use the JWT token from localStorage
                }
            });
    
            // console.log("Response data:", response.data);
    
            // Update your frontend state to reflect the new credits
            setCredits(response.data.credits);
            // Optionally update local storage or other caches if necessary
        } catch (error) {
            console.error('Error updating credits:', error);
            // Handle error (show feedback to the user, etc.)
            // If you want to specifically handle HTTP error statuses, you can check error.response.status
        }
    };
    
    /*********************************************************************************** */
  

    const navigateToFeatures = () => {
        navigate('/features'); // Navigate to /#features
    };

    useEffect(() => {
        // Load the Google API client library
        const script = document.createElement("script");
        script.src = "https://apis.google.com/js/api.js";
        script.onload = () => {
          // Initialize the Google API client library
          gapi.load("auth2", () => {
            gapi.auth2.init({
              client_id:
                "584832623015-02ioa5kbjqp9agd30pdiifln0bhb5trb.apps.googleusercontent.com",
              scope: "profile email",
              redirect_uri: "https://tylo.ai/", 
              cookiepolicy: "single_host_origin",
            });
          });
        };
        document.body.appendChild(script);
        
        // Load research results from local storage
        const loadedResearchResults = JSON.parse(localStorage.getItem('researchResults')) || [];
        if (loadedResearchResults.length === 0) {
            console.log('No research results found.');
            // Perform any actions you want when there are no results
            // For example, you might want to set a flag or load default data
        } else {
            // If there are results, set them into the state`   
            setSavedResearchResults(loadedResearchResults);
        }

        const loadedUseCaseResults = JSON.parse(localStorage.getItem('useCaseResults')) || [];
        if (loadedUseCaseResults.length === 0) {
            console.log('No Use Case results found.');
            // Perform any actions you want when there are no results
            // For example, you might want to set a flag or load default data
        } else {
            // If there are results, set them into the state
            setSavedUseCaseResults(loadedUseCaseResults);
        }

        const loadedResearcherResults = JSON.parse(localStorage.getItem('researcherResults')) || [];
        if (loadedResearcherResults.length === 0) {
            console.log('No Researcher results found.');
            // Perform any actions you want when there are no results
            // For example, you might want to set a flag or load default data
        } else {
            // If there are results, set them into the state
            setSavedResearcherResults(loadedResearcherResults);
        }

        const loadedNewsResults = JSON.parse(localStorage.getItem('newsResults')) || [];
        if (loadedNewsResults.length === 0) {
            console.log('No News results found.');
            // Perform any actions you want when there are no results
            // For example, you might want to set a flag or load default data
        } else {
            // If there are results, set them into the state
            setSavedNewsResults(loadedNewsResults);
        }

        const loadedPatentResults = JSON.parse(localStorage.getItem('patentResults')) || [];
        if (loadedPatentResults.length === 0) {
            console.log('No Patent results found.');
            // Perform any actions you want when there are no results
            // For example, you might want to set a flag or load default data
        } else {
            // If there are results, set them into the state
            setSavedPatentResults(loadedPatentResults);
        }

        const loadedOrgResults = JSON.parse(localStorage.getItem('orgResults')) || [];
        if (loadedOrgResults.length === 0) {
            console.log('No Org results found.');
            // Perform any actions you want when there are no results
            // For example, you might want to set a flag or load default data
        } else {
            // If there are results, set them into the state
            setSavedOrgResults(loadedOrgResults);
        }
        
      const loadedCollabResults = JSON.parse(localStorage.getItem('collabResults')) || [];
        if (loadedCollabResults.length === 0) {
            console.log('No Collab results found.');
            // Perform any actions you want when there are no results
            // For example, you might want to set a flag or load default data
        } else {
            // If there are results, set them into the state
            setSavedCollabResults(loadedCollabResults);
        }

        fetch("https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/profile", {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          })
          .then(response => response.json())
          .then(data => {
            setCredits(data.credits);
            // console.log('credits backend:', data.credits)
        })
        .catch(error => console.error('Error fetching user data:', error));
        
      }, [token]);



      const getAppState = () => {
        // var textareaValue = document.querySelector('.inquire-textbox').value;
        const userId = localStorage.getItem('tyloEmail'); 
        return {
            userId,
            
          // Add other state elements as needed
        };
      };

    const handleSubmit = () => {
        const token = localStorage.getItem('token'); // Ensure you're getting the auth token correctly
        const appState = getAppState(); 
        
        setButtonText('Submitted');
        setButtonColor('submit-button-green');
    
        // Replace the URL with your actual endpoint
        const url = "https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/message";
    
        axios.post(url, {
          message,
          appState: JSON.stringify(appState),
        }, {
          headers: {
            "Authorization": `Bearer ${token}`, // Adjust this according to your token auth setup
            "Content-Type": "application/json",
          },
        }).then(response => {
          console.log('Message submitted:', response.data);
          setMessage(''); // Clear the message input after successful submission
        }).catch(error => {
          console.error('Error submitting message:', error);
        });

        setTimeout(() => {
            setButtonText('Submit');
            setButtonColor('submit-button'); // Revert to the initial class
        }, 3000);
    };



    const handleSignOutClick = () => {
        // Sign out from your application
        // This might involve removing the user's session or token from local storage
        localStorage.removeItem('token'); // For example, if you store a session token

        // If using Google sign-in and you want to sign the user out of Google as well
        const auth2 = gapi.auth2.getAuthInstance();
        if (auth2 != null) {
            auth2.signOut().then(auth2.disconnect().then(() => {
                console.log('User signed out of Google.');
                navigate('/#');
            }));
        }

        navigate('/#'); // -1 takes you one page back in the history
        // Redirect the user to the sign-in page or another appropriate page
        // This depends on your routing setup, for example using react-router
        // navigate('/signin'); // Example using react-router
    };
    

    const combined = process.env.REACT_APP_OPEN_AI_KEY


    


    /******************************************** Open AI calls *******************************************************************/
    // const trackerOpenAI = async (Callprompt, finalTexts) => {
  
    //     const systemPrompt = `Answer the following prompt using the context given `;
    //     const userPrompt = `Prompt: ${Callprompt} \nContext: ${finalTexts}`;
    
    //     const prompt = [
    //         { "role": "system", "content": systemPrompt },
    //         { "role": "user", "content": userPrompt }
    //     ];
    
        
    //     try {
    //         const response = await fetch("https://api.openai.com/v1/chat/completions", {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Authorization": `Bearer ${combined}` 
    //                 // Replace with your actual API key
    //             },
    //             body: JSON.stringify({
    //                 model: "gpt-3.5-turbo-16k",
    //                 messages: prompt,
    //                 temperature: 0.7,
    //                 max_tokens: 4096,
    //                 top_p: 1,
    //                 frequency_penalty: 0,
    //                 presence_penalty: 0
    //             })
    //         });

    //         if (!response.ok) {
    //             throw new Error(`HTTP error! Status: ${response.status}`);
    //         }
    //         deductCredits(1);
    //         const data = await response.json();
    //         return data.choices[0].message.content;
            
    //     } catch (error) {
    //         console.error("Error calling OpenAI API:", error);
    //         return null;
            
    //     }
    // }

    const trackerOpenAI_1 = async (Callprompt, finalTexts) => {
  
        const systemPrompt = `Answer the following prompt using the context given `;
        const userPrompt = `Prompt: ${Callprompt} \nContext: ${finalTexts}`;
    
        const prompt = [
            { "role": "system", "content": systemPrompt },
            { "role": "user", "content": userPrompt }
        ];
    
        try {
            const response = await fetch("https://api.openai.com/v1/chat/completions", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${combined}` 
                    // Replace with your actual API key
                },
                body: JSON.stringify({
                    model: "gpt-3.5-turbo-16k",
                    messages: prompt,
                    temperature: 0.7,
                    max_tokens: 4096,
                    top_p: 1,
                    frequency_penalty: 0,
                    presence_penalty: 0
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            deductCredits(1);
            const data = await response.json();
            return data.choices[0].message.content;
        } catch (error) {
            console.error("Error calling OpenAI API:", error);
            return null;
            
        }
    }

    const trackerOpenAI_2 = async (Callprompt, finalTexts) => {
  
        const systemPrompt = `Answer the following prompt using the context given `;
        const userPrompt = `Prompt: ${Callprompt} \nContext: ${finalTexts}`;
    
        const prompt = [
            { "role": "system", "content": systemPrompt },
            { "role": "user", "content": userPrompt }
        ];
    
        try {
            const response = await fetch("https://api.openai.com/v1/chat/completions", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${combined}` 
                    // Replace with your actual API key
                },
                body: JSON.stringify({
                    model: "gpt-3.5-turbo-16k",
                    messages: prompt,
                    temperature: 0.7,
                    max_tokens: 4096,
                    top_p: 1,
                    frequency_penalty: 0,
                    presence_penalty: 0
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            deductCredits(1);
            const data = await response.json();
            return data.choices[0].message.content;
        } catch (error) {
            console.error("Error calling OpenAI API:", error);
            return null;
            
        }
    }

    const trackerOpenAI_3 = async (Callprompt, finalTexts) => {
  
        const systemPrompt = `Answer the following prompt using the context given `;
        const userPrompt = `Prompt: ${Callprompt} \nContext: ${finalTexts}`;
    
        const prompt = [
            { "role": "system", "content": systemPrompt },
            { "role": "user", "content": userPrompt }
        ];
    
        try {
            const response = await fetch("https://api.openai.com/v1/chat/completions", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${combined}` 
                    // Replace with your actual API key
                },
                body: JSON.stringify({
                    model: "gpt-3.5-turbo-16k",
                    messages: prompt,
                    temperature: 0.7,
                    max_tokens: 4096,
                    top_p: 1,
                    frequency_penalty: 0,
                    presence_penalty: 0
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            deductCredits(1);
            const data = await response.json();
            return data.choices[0].message.content;
        } catch (error) {
            console.error("Error calling OpenAI API:", error);
            return null;
            
        }
    }

    const trackerOpenAI_4 = async (Callprompt, finalTexts) => {
  
        const systemPrompt = `Answer the following prompt using the context given `;
        const userPrompt = `Prompt: ${Callprompt} \nContext: ${finalTexts}`;
    
        const prompt = [
            { "role": "system", "content": systemPrompt },
            { "role": "user", "content": userPrompt }
        ];
    
        try {
            const response = await fetch("https://api.openai.com/v1/chat/completions", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${combined}` 
                    // Replace with your actual API key
                },
                body: JSON.stringify({
                    model: "gpt-3.5-turbo-16k",
                    messages: prompt,
                    temperature: 0.7,
                    max_tokens: 4096,
                    top_p: 1,
                    frequency_penalty: 0,
                    presence_penalty: 0
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            deductCredits(1);
            const data = await response.json();
            return data.choices[0].message.content;
        } catch (error) {
            console.error("Error calling OpenAI API:", error);
            return null;
            
        }
    }

    /*************************************************************************************************** */

    
    /************************************ Supabase call ************************************/

    async function fetchRecentPaperByAuthor(authorName) {
        // Initialize variables to store the most recent paper details
        let recentYear = 0;
        let recentId = 0;
        let recentTitle = '';
        let recentParagraph = '';
    
        // Loop through the years from 2023 to 2010
        for (let year = 2023; year >= 2010; year--) {
            const tableName = `arxiv_${year}`; // Construct the table name based on the year
    
            let { data, error } = await supabase
                .from(tableName) // Use the constructed table name
                .select('id, title, paragraph') // Retrieve the necessary fields
                .ilike('authors', `%${authorName}%`) // Use case-insensitive search for the author's name
                .order('id', { ascending: false }); // Order by 'id' descending to get the most recent paper first
    
            if (error) {
                console.error(`Error fetching data from ${tableName}:`, error);
                continue; // Skip to the next year if there's an error
            }
    
            // Check if any papers were found for this year and author
            if (data.length > 0) {
                // Assuming that the first record is the most recent one
                const mostRecentPaper = data[0];
                // Update if this is the most recent paper overall
                if (year > recentYear || mostRecentPaper.id > recentId) {
                    recentYear = year;
                    recentId = mostRecentPaper.id;
                    recentTitle = mostRecentPaper.title;
                    recentParagraph = mostRecentPaper.paragraph;
                }
            }
        }
    
        // Check if a recent paper was found and log the details
        if (recentId > 0) {
            console.log(`Most recent paper by ${authorName} found in arxiv_${recentYear}:`, {
                Title: recentTitle,
                Paragraph: recentParagraph
            });
        } else {
            console.log(`No papers found by author: ${authorName}`);
        }
        return { recentTitle, recentParagraph };
    }

    /********************************************************************************************************* */
   

    
   
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const handleSelectionChange = (optionNumber, event) => {
        console.log('selector called')
        const newValue = event.target.value;
        switch (optionNumber) {
            case 1:
                setSelectedOption1(newValue);
                // console.log('new vale is', newValue )
                break;
            case 2:
                setSelectedOption2(newValue);
                break;
            case 3:
                setSelectedOption3(newValue);
                break;
            case 4:
                setSelectedOption4(newValue);
                break;
            case 5:
                setSelectedOption5(newValue);
                break;
            case 6:
                setSelectedOption6(newValue);
                break;
            case 7:
                setSelectedOption7(newValue);
                break;
            default:
                // Handle unexpected option number
                break;
        }
        
    };
    

    const onResearchSubmit = async () => {
        closeModal();
        
        const newSelectionCounts = { ...selectionCounts };
        const selectedOptions = [selectedOption1, selectedOption2, selectedOption3, selectedOption4, selectedOption5, selectedOption6, selectedOption7];
        const currentBodys = [currentBody1, currentBody2, currentBody3, currentBody4, currentBody5, currentBody6, currentBody7]
        // const newCards = [];
        console.log('selected option is', selectedOption1 )
        for (let i = 0; i < selectedOptions.length; i++){
            const selectedOption = selectedOptions[i];
            const currentBody = currentBodys[i].trim();
            console.log('selected option',[i], 'is', selectedOption )
            console.log('Current body',[i], 'is', currentBody )

            if (currentBody !== '') { // Only proceed if the body is not empty
                 // Reset the count for the selected option before updating
                 if (newSelectionCounts[selectedOption] !== undefined) {
                    // This will clear previous entries for the category by resetting its count
                    newSelectionCounts[selectedOption] = 0;
                }

                switch (selectedOption) {
                    case 'Research':
                        await handleResearch(currentBody);
                        newSelectionCounts.Research += 1;
                        break;
                    case 'Use Case':
                        await handleUseCase(currentBody);
                        newSelectionCounts.UseCase += 1;
                        break;
                    case 'News':
                        await handleNews(currentBody);
                        newSelectionCounts.News += 1;
                        break;
                    case 'Researcher':
                        await handleResearcher(currentBody);
                        newSelectionCounts.Researcher += 1;
                        break;
                    case 'Organization':
                        await handleOrganization(currentBody);
                        newSelectionCounts.Organization += 1;
                        break;
                    case 'Patent':
                        await handlePatent(currentBody);
                        newSelectionCounts.Patent += 1;
                        break;
                    case 'Collaboration':
                        await handleCollaboration(currentBody);
                        newSelectionCounts.Collaboration += 1;
                        break;
                    default:
                        // Fallback logic or handling for unexpected selectedOption values
                        break;
                    // Add more cases if there are more than these types
                }
                
            }
        }
    
        setSelectionCounts(newSelectionCounts);  // Update the overall count state
    };

    async function makeTrack(query, promptType, token) {
        const url = 'https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/track';
    
        const body = {
            question: query,
            // Add any other fields needed by GeneralInquiryDto
        };
    
        const params = new URLSearchParams({ promptType });
    
        const response = await fetch(`${url}?${params.toString()}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // If you have an authentication token
            },
            body: JSON.stringify(body),
        });
    
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
        return data;
    }
    

    const handleResearch = async (currentBody) => {
        // console.log('Research function running using ', currentBody)
        setResearchHeader('Research');
       
       // Combine the research header and body to form the full query
        let fullQuery = `${researchHeader} in ${currentBody}`;


        try {
            // Await the response from the backend `track` API
            const response = await makeTrack(fullQuery, 'Research', token); // Replace with actual token
    
            if (response.message && response.supporting_evidence) {
                // Construct the summary text directly from response.message
                let summaryText = response.message;
    
                // Update state with the returned data
                if (summaryText) {
                    const newResult = {
                        id: Date.now() + Math.floor(Math.random() * 1000), // Ensuring a unique ID
                        title: `Research into ${currentBody}`,
                        body: summaryText,
                        date: new Date().toISOString(),
                    };
    
                    setResearchResults(prevResults => [...prevResults, newResult]);
                    setBodyResearch(summaryText); // Update state with the full text
                    console.log(`output text ${bodyResearch}`);
    
                    // Save the results to local storage
                    let existingResults = JSON.parse(localStorage.getItem('researchResults'));
    
                    if (!Array.isArray(existingResults) && typeof existingResults === 'object') {
                        console.log('Correcting the structure of existingResults');
                        existingResults = [existingResults]; // Make it an array containing the existing object
                    } else if (!existingResults) {
                        // If existingResults is neither an array nor an object (e.g., null or undefined)
                        existingResults = [];
                    }
    
                    const newId = Date.now() + Math.floor(Math.random() * 1000);
                    existingResults.push({
                        id: newId,
                        title: `Research into ${currentBody}`,
                        body: summaryText,
                        date: new Date().toISOString() // Save the current date and time for each entry
                    });
    
                    localStorage.setItem('researchResults', JSON.stringify(existingResults));
                } else {
                    console.error("Response message is empty or not in the expected format");
                }
            } else {
                console.error("Backend response is empty or not in the expected format");
            }
        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
        }
        // let encodedQuery = encodeURIComponent(fullQuery);

        // // Construct the URL with the query
        // let apiUrl = `https://archive.tylo.ai/google_query/google?question=${encodedQuery}`;

        // try {
        //     // Await the response directly within the async function
        //     const response = await fetch(apiUrl);
        //     if (!response.ok) {
        //         throw new Error('Network response was not ok.');
        //     }

        //     const articles = await response.json();
        //     if (articles && articles.length > 0) {
        //         // Taking the top three, might set a hard threshold later
        //         const topArticles = articles.sort((a, b) => b.relevance - a.relevance).slice(0, 3);

        //         // Map over these articles to create a structured summary request
        //         const summaries = await Promise.all(topArticles.map((article, index) => 
        //             trackerOpenAI(`Summarize this like an expert, dont make mention of Google scholar`, article.full_text).then(summary => 
        //                 ({ 
        //                     title: article.title, 
        //                     summary, 
        //                     url: article.article_url // Include the article URL
        //                 })
        //             )
        //         ));

        //         // Construct the summary in a format with numbers, bullet points, and URLs
        //         let summaryText = summaries.map((item, index) => 
        //             `${index + 1}. ${item.title}: \n${item.summary}\n - URL: ${item.url}`
        //         ).join('\n\n'); // Separate each article block with two newlines for clarity
               

        //         // // Now call trackerOpenAI asynchronously within the async context of handleResearch
        //         // const summaryData = await trackerOpenAI('Summarise the context like an article', details.paragraph);
                 
                 
        //         // Update state with the returned data
        //         if (summaryText) {
        //             // Inside handleResearch, after processing and getting summaryText

        //             const newResult = {
        //                 id: Date.now() + Math.floor(Math.random() * 1000), // Ensuring a unique ID
        //                 title: `Research into ${currentBody}`,
        //                 body: summaryText,
        //                 date: new Date().toISOString(),
        //             };

        //             setResearchResults(prevResults => [...prevResults, newResult]);

        //             setBodyResearch(summaryText); // Update state with the full text
        //             console.log(`output text ${bodyResearch}`)

        //             // Save the results to local storage
        //             let existingResults = JSON.parse(localStorage.getItem('researchResults'));
                    
        //             if (!Array.isArray(existingResults) && typeof existingResults === 'object') {
        //                 console.log('Correcting the structure of existingResults');
        //                 existingResults = [existingResults]; // Make it an array containing the existing object
        //             } else if (!existingResults) {
        //                 // If existingResults is neither an array nor an object (e.g., null or undefined)
        //                 existingResults = [];
        //             }

        //             const newId = Date.now() + Math.floor(Math.random() * 1000);
        //             existingResults.push({
        //                 id: newId,
        //                 title: `Research into ${currentBody}`,
        //                 body: summaryText,
        //                 date: new Date().toISOString() // Save the current date and time for each entry
        //             });

        //             localStorage.setItem('researchResults', JSON.stringify(existingResults));
        //         } else {
        //             console.error("OpenAI response is empty or not in the expected format");
        //         }
                
        //     }
        // } catch (error) {
        //     console.error('There has been a problem with your fetch operation:', error);
        // }
        
    };
    // const handlePatent = async () => {
    //     return `${patentHeader} of ${patentBody}`;
    // };
    const handleUseCase = async (currentBody) => {
        setUseCaseHeader('Use Case');
        let fullQuery = `${useCaseHeader} of ${currentBody}`;
        let encodedQuery = encodeURIComponent(fullQuery);

        // Construct the URL with the query
        let apiUrl = `https://archive.tylo.ai/google_scholar/google?question=${encodedQuery}`;

        try {
            // Await the response directly within the async function
            const response = await fetch(apiUrl);
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const articles = await response.json();
            if (articles && articles.length > 0) {
                
                // Find the article with the highest relevance
                let highestRelevanceArticle = articles.reduce((max, article) => max.relevance > article.relevance ? max : article, articles[0]);

                // Extract and use the necessary details from the article
                const details = {
                    title: highestRelevanceArticle.title,
                    id: highestRelevanceArticle.id,
                    articleUrl: highestRelevanceArticle.article_url,
                    paragraph: highestRelevanceArticle.paragraph
                };

                // Now call trackerOpenAI asynchronously within the async context of handleResearch
                const summaryData = await trackerOpenAI_1('Present a use case and be direct and confident like an professional on', details.paragraph);
                // setTitleUseCase(details.title);
                // Update state with the returned data
                if (summaryData) {
                    const newResult = {
                        id: Date.now() + Math.floor(Math.random() * 1000), // Ensuring a unique ID
                        title: `Use Case of ${currentBody}`,
                        body: summaryData,
                        date: new Date().toISOString(), // Save the current date and time for each entry
                        ref: details.articleUrl
                    };

                    setUseCaseResults(prevResults => [...prevResults, newResult]);

                    // const fullText = summaryData; // Full text from OpenAI
                    // setBodyUseCase(fullText); // Update state with the full text
                    // setRefUseCase(details.articleUrl);

                    // Save the results to local storage
                    let existingResults = JSON.parse(localStorage.getItem('useCaseResults'));
                    
                    if (!Array.isArray(existingResults) && typeof existingResults === 'object') {
                        console.log('Correcting the structure of existingResults');
                        existingResults = [existingResults]; // Make it an array containing the existing object
                    } else if (!existingResults) {
                        // If existingResults is neither an array nor an object (e.g., null or undefined)
                        existingResults = [];
                    }
                    const newId = Date.now() + Math.floor(Math.random() * 1000);

                    existingResults.push({
                        id: newId,
                        title: `Use Case of ${currentBody}`,
                        body: summaryData,
                        date: new Date().toISOString(), // Save the current date and time for each entry
                        ref: details.articleUrl
                    });

                    localStorage.setItem('useCaseResults', JSON.stringify(existingResults));

                } else {
                    console.error("OpenAI response is empty or not in the expected format");
                }
                
            }
        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
        }
    };
    const handleResearcher = async (currentBody) => {
        // setResearcherHeader('Researcher');
        // Call the function and wait for the result
        const { recentTitle, recentParagraph } = await fetchRecentPaperByAuthor(currentBody);

        // Set the title and paragraph for the researcher
        // setTitleResearcher(recentTitle);
        // setBodyResearcher(recentParagraph);
        let existingResults = JSON.parse(localStorage.getItem('researcherResults'));
                    
        if (!Array.isArray(existingResults) && typeof existingResults === 'object') {
            console.log('Correcting the structure of existingResults');
            existingResults = [existingResults]; // Make it an array containing the existing object
        } else if (!existingResults) {
             // If existingResults is neither an array nor an object (e.g., null or undefined)
            existingResults = [];
        }

        const newResult = {
            id: Date.now() + Math.floor(Math.random() * 1000), // Ensuring a unique ID
            title: `${recentTitle}`,
            body: recentParagraph,
            date: new Date().toISOString(), 
        };

        setRchResults(prevResults => [...prevResults, newResult]);

        existingResults.push({
            id: Date.now() + Math.floor(Math.random() * 1000),
            title: `${recentTitle}`,
            body: recentParagraph,
            date: new Date().toISOString() // Save the current date and time for each entry
        });

        localStorage.setItem('researcherResults', JSON.stringify(existingResults));
    };

    const handleNews = async (currentBody) => {
        setNewsHeader('News');
        let fullQuery = `${newsHeader} on ${currentBody}`;
        let encodedQuery = encodeURIComponent(fullQuery);

        // Construct the URL with the query
        let apiUrl1 = `https://archive.tylo.ai/google_query/google?question=${encodedQuery}`;
        

        try {
            // Await the response directly within the async function
            const response = await fetch(apiUrl1);
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const articles = await response.json();
            if (articles && articles.length > 0) {
                const filteredArticles = articles.filter(article => article.id.startsWith('news'));
                // Find the article with the highest relevance
                if (filteredArticles.length > 0) {
                    let highestRelevanceArticle = articles.reduce((max, article) => max.relevance > article.relevance ? max : article, articles[0]);
                
                    // Extract and use the necessary details from the article
                    const details = {
                        title: highestRelevanceArticle.title,
                        id: highestRelevanceArticle.id,
                        articleUrl: highestRelevanceArticle.article_url,
                        full_text: highestRelevanceArticle.full_text
                    };

                    // Now call trackerOpenAI asynchronously within the async context of handleResearch
                    const summaryData = await trackerOpenAI_2('Summarise this like a news article', details.full_text);
                    // setTitleNews(details.title);
                    // Update state with the returned data
                    if (summaryData) {
                        const newResult = {
                            id: Date.now() + Math.floor(Math.random() * 1000), // Ensuring a unique ID
                            title: `News on ${currentBody}`,
                            body: summaryData,
                            date: new Date().toISOString(), // Save the current date and time for each entry
                            ref: details.articleUrl
                        };
                        setNewsResults(prevResults => [...prevResults, newResult]);

                        // const fullText = summaryData; // Full text from OpenAI
                        // setBodyNews(fullText); // Update state with the full text
                        // setRefNews(details.articleUrl)

                        //saver
                        let existingResults = JSON.parse(localStorage.getItem('newsResults'));
                                    
                        if (!Array.isArray(existingResults) && typeof existingResults === 'object') {
                            console.log('Correcting the structure of existingResults');
                            existingResults = [existingResults]; // Make it an array containing the existing object
                        } else if (!existingResults) {
                            // If existingResults is neither an array nor an object (e.g., null or undefined)
                            existingResults = [];
                        }

                        existingResults.push({
                            id: Date.now() + Math.floor(Math.random() * 1000),
                            title: `News on ${currentBody}`,
                            body: summaryData,
                            date: new Date().toISOString(), // Save the current date and time for each entry
                            ref: details.articleUrl
                        });

                        localStorage.setItem('newsResults', JSON.stringify(existingResults));

                    } else {
                        console.error("OpenAI response is empty or not in the expected format");
                    }
                } else {
                    console.log('No articles found with ID starting with "news"');
                    // Handle the case where no articles meet the condition, if necessary
                } 
            }
        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
        }
    };

    const extractTextForGPT = (fullText, maxLength = 16000) => {
        // Check if the full text is shorter than the maximum length
        if (fullText.length <= maxLength) {
            return fullText;
        }
        // If the text is longer, return the first 'maxLength' characters
        return fullText.substring(0, maxLength);
    };

    const handlePatent = async (currentBody) => {
        let encodedQuery = encodeURIComponent(currentBody);
        let apiUrl = `https://archive.tylo.ai/google_patent/google?question=${encodedQuery}`
    
        try {
            // Await the response directly within the async function
            const response = await fetch(apiUrl);
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const articles = await response.json();
            if (articles && articles.length > 0) {
                const filteredArticles = articles.filter(article => article.id.startsWith('patent'));
                // Find the article with the highest relevance
                if (filteredArticles.length > 0) {
                    // Find the article with the highest relevance from the filtered list
                    let highestRelevanceArticle = filteredArticles.reduce((max, article) => max.relevance > article.relevance ? max : article, filteredArticles[0]);
            
                    // Extract and use the necessary details from the article
                    const details = {
                        title: highestRelevanceArticle.title,
                        id: highestRelevanceArticle.id,
                        articleUrl: highestRelevanceArticle.article_url,
                        full_text: highestRelevanceArticle.full_text
                    };
                    
                    const extractedText = extractTextForGPT(details.full_text, 16000);
                    console.log('extracted text ', extractedText);
                     // Now call trackerOpenAI asynchronously within the async context of handleResearch
                    const summaryData = await trackerOpenAI_3('Present this patent information professionally, add abstract, Introduction, Description of Invention, Key Features, conclusions etc', extractedText);
                    console.log('full text', details.full_text);
                    // setTitlePatent(`Patent information on ${currentBody}`);
                    // Update state with the returned data
                    if (summaryData) {
                        const newResult = {
                            id: Date.now() + Math.floor(Math.random() * 1000), // Ensuring a unique ID
                            title: `Patent information on ${currentBody}`,
                            body: summaryData,
                            date: new Date().toISOString(), // Save the current date and time for each entry
                            ref: details.articleUrl
                        };
                        setPatentResults(prevResults => [...prevResults, newResult]);

                        // const fullText = summaryData; // Full text from OpenAI
                        // setBodyPatent(fullText); // Update state with the full text
                        // setRefPatent(details.articleUrl);

                        //saver
                        let existingResults = JSON.parse(localStorage.getItem('patentResults'));
                                    
                        if (!Array.isArray(existingResults) && typeof existingResults === 'object') {
                            console.log('Correcting the structure of existingResults');
                            existingResults = [existingResults]; // Make it an array containing the existing object
                        } else if (!existingResults) {
                            // If existingResults is neither an array nor an object (e.g., null or undefined)
                            existingResults = [];
                        }

                        existingResults.push({
                            id: Date.now() + Math.floor(Math.random() * 1000),
                            title: `Patent information on ${currentBody}`,
                            body: summaryData,
                            date: new Date().toISOString(), // Save the current date and time for each entry
                            ref: details.articleUrl
                        });

                        localStorage.setItem('patentResults', JSON.stringify(existingResults));


                    } else {
                        console.error("OpenAI response is empty or not in the expected format");
                    }
                } else {
                    console.log('No articles found with ID starting with "org"');
                    // Handle the case where no articles meet the condition, if necessary
                } 
            }
        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
        }
    }

    const handleOrganization = async (currentBody) => {

        let fullQuery = `Latest Advancements in ${currentBody}`;
        let encodedQuery = encodeURIComponent(fullQuery);
        let apiUrl = `https://archive.tylo.ai/google_org/google?question=${encodedQuery}`;
       
        try {
            // Await the response directly within the async function
            const response = await fetch(apiUrl);
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const articles = await response.json();
            if (articles && articles.length > 0) {
                const filteredArticles = articles.filter(article => article.id.startsWith('org'));
                // Find the article with the highest relevance
                if (filteredArticles.length > 0) {
                    // Find the article with the highest relevance from the filtered list
                    let highestRelevanceArticle = filteredArticles.reduce((max, article) => max.relevance > article.relevance ? max : article, filteredArticles[0]);
            
                    // Extract and use the necessary details from the article
                    const details = {
                        title: highestRelevanceArticle.title,
                        id: highestRelevanceArticle.id,
                        articleUrl: highestRelevanceArticle.article_url,
                        full_text: highestRelevanceArticle.full_text
                    };
                     // Now call trackerOpenAI asynchronously within the async context of handleResearch
                    const summaryData = await trackerOpenAI_4('Present the information on the organisation like an expert', details.title);
                    // setTitleOrganization(`Organization information on ${currentBody}`);
                    // Update state with the returned data
                    if (summaryData) {
                        const newResult = {
                            id: Date.now() + Math.floor(Math.random() * 1000), // Ensuring a unique ID
                            title: `Latest Advancements in ${currentBody} `,
                            body: summaryData,
                            date: new Date().toISOString(), // Save the current date and time for each entry
                            ref: details.articleUrl
                        };
                        setOrgResults(prevResults => [...prevResults, newResult]);

                        // const fullText = summaryData; // Full text from OpenAI
                        // setBodyOrganization(fullText); // Update state with the full text
                        // setRefOrg(details.articleUrl)


                        //saver
                        let existingResults = JSON.parse(localStorage.getItem('orgResults'));
                                    
                        if (!Array.isArray(existingResults) && typeof existingResults === 'object') {
                            console.log('Correcting the structure of existingResults');
                            existingResults = [existingResults]; // Make it an array containing the existing object
                        } else if (!existingResults) {
                            // If existingResults is neither an array nor an object (e.g., null or undefined)
                            existingResults = [];
                        }

                        existingResults.push({
                            id: Date.now() + Math.floor(Math.random() * 1000),
                            title: `Latest Advancements in ${currentBody}`,
                            body: summaryData,
                            date: new Date().toISOString(), // Save the current date and time for each entry
                            ref: details.articleUrl
                        });

                        localStorage.setItem('orgResults', JSON.stringify(existingResults));


                    } else {
                        console.error("OpenAI response is empty or not in the expected format");
                    }
                } else {
                    console.log('No articles found with ID starting with "org"');
                    // Handle the case where no articles meet the condition, if necessary
                } 
            }
        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
        }
    }

    const handleCollaboration = async (currentBody) => {

        let fullQuery = `Recent collaborations with ${currentBody}`;
        let encodedQuery = encodeURIComponent(fullQuery);
        let apiUrl = `https://archive.tylo.ai/google_org/google?question=${encodedQuery}`;
       
        try {
            // Await the response directly within the async function
            const response = await fetch(apiUrl);
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const articles = await response.json();
            if (articles && articles.length > 0) {
                const filteredArticles = articles.filter(article => article.id.startsWith('collab'));
                // Find the article with the highest relevance
                if (filteredArticles.length > 0) {
                    // Find the article with the highest relevance from the filtered list
                    let highestRelevanceArticle = filteredArticles.reduce((max, article) => max.relevance > article.relevance ? max : article, filteredArticles[0]);
            
                    // Extract and use the necessary details from the article
                    const details = {
                        title: highestRelevanceArticle.title,
                        id: highestRelevanceArticle.id,
                        articleUrl: highestRelevanceArticle.article_url,
                        full_text: highestRelevanceArticle.full_text
                    };
                     // Now call trackerOpenAI asynchronously within the async context of handleResearch
                    const summaryData = await trackerOpenAI_4('Present the information on the Collaborations like an expert', details.title);
                    // setTitleCollab(`Collaboration information on ${currentBody}`);
                    // Update state with the returned data
                    if (summaryData) {
                        const newResult = {
                            id: Date.now() + Math.floor(Math.random() * 1000), // Ensuring a unique ID
                            title: `Recent collaborations with ${currentBody}`,
                            body: summaryData,
                            date: new Date().toISOString(), // Save the current date and time for each entry
                            ref: details.articleUrl
                        };
                        setCollabResults(prevResults => [...prevResults, newResult]);

                        // const fullText = summaryData; // Full text from OpenAI
                        // setBodyCollab(fullText); // Update state with the full text
                        // setRefCollab(details.articleUrl)


                        //saver
                        let existingResults = JSON.parse(localStorage.getItem('collabResults'));
                                    
                        if (!Array.isArray(existingResults) && typeof existingResults === 'object') {
                            console.log('Correcting the structure of existingResults');
                            existingResults = [existingResults]; // Make it an array containing the existing object
                        } else if (!existingResults) {
                            // If existingResults is neither an array nor an object (e.g., null or undefined)
                            existingResults = [];
                        }

                        existingResults.push({
                            id: Date.now() + Math.floor(Math.random() * 1000),
                            title: `Recent collaborations with ${currentBody}`,
                            body: summaryData,
                            date: new Date().toISOString(), // Save the current date and time for each entry
                            ref: details.articleUrl
                        });

                        localStorage.setItem('collabResults', JSON.stringify(existingResults));


                    } else {
                        console.error("OpenAI response is empty or not in the expected format");
                    }
                } else {
                    console.log('No articles found with ID starting with "collab"');
                    // Handle the case where no articles meet the condition, if necessary
                } 
            }
        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
        }
    }

    /************************************ Deleting cards ***************************************/
    const handleDelete = (id) => {
        setResearchResults(prevResults => prevResults.filter(result => result.id !== id));
    };

      const deleteResearchResult = (resultId) => {
        // Filter out the result to be deleted
        const updatedResults = savedResearchResults.filter(result => result.id !== resultId);
        
        // Update state
        setSavedResearchResults(updatedResults);
        
        // Update local storage
        localStorage.setItem('researchResults', JSON.stringify(updatedResults));
      };
    
      const deleteUseCaseResult = (resultId) => {
        const existingResults = savedUseCaseResults.filter(result => result.id !== resultId);
        setSavedUseCaseResults(existingResults);
        localStorage.setItem('useCaseResults', JSON.stringify(existingResults));
    };

    const deleteResearcherResult = (resultId) => {
        const existingResults = savedResearcherResults.filter(result => result.id !== resultId);
        setSavedUseCaseResults(existingResults);
        localStorage.setItem('researcherResults', JSON.stringify(existingResults));
    };

    const deleteNewsResult = (resultId) => {
        const existingResults = savedNewsResults.filter(result => result.id !== resultId);
        setSavedUseCaseResults(existingResults);
        localStorage.setItem('newsResults', JSON.stringify(existingResults));
    };

    const deletePatentResult = (resultId) => {
        const existingResults = savedPatentResults.filter(result => result.id !== resultId);
        setSavedUseCaseResults(existingResults);
        localStorage.setItem('patentResults', JSON.stringify(existingResults));
    };


    const deleteOrgResult = (resultId) => {
        const existingResults = savedOrgResults.filter(result => result.id !== resultId);
        setSavedUseCaseResults(existingResults);
        localStorage.setItem('orgResults', JSON.stringify(existingResults));
    };

    const deleteCollabResult = (resultId) => {
        const existingResults = savedCollabResults.filter(result => result.id !== resultId);
        setSavedUseCaseResults(existingResults);
        localStorage.setItem('collabResults', JSON.stringify(existingResults));
    };
    
    const deleteAllResults = () => {
        // Clear from local storage
        localStorage.removeItem('researchResults');
        localStorage.removeItem('useCaseResults');
        localStorage.removeItem('researcherResults');
        localStorage.removeItem('newsResults');
        localStorage.removeItem('patentResults');
        localStorage.removeItem('orgResults');
        localStorage.removeItem('collabResults');
        
        // Update state
        setSavedResearchResults([]);
        setSavedUseCaseResults([]);
        setSavedResearcherResults([]);
        setSavedNewsResults([]);
        setSavedPatentResults([]);
        setSavedOrgResults([]);
        setSavedCollabResults([]);
    };


    /*************************************************************************************** */
    console.log('Track component about to render JSX');
    return (
        <div className="feature-page">
            
                <div className="feature-page-icon">
                    <img src={Tylo_icon} alt="Tylo Icon" className="tylo-icon" />
                    <div className="tabs">
                        <div className="top-bar-tab-current_page">
                            <a href="/#features" className="top-bar-text">Features</a> {/* Link to the Features page */}
                        </div>
                        <div className="top-bar-tab">
                            <a href="/#about" className="top-bar-text">About Us</a> {/* Link to the About page */}
                        </div>
                    </div>
                    <img src={googleProfileImageUrl} alt="Profile Icon" onClick={navigateToSettings} className="profile-icon" />
                        <div className="login-button" onClick={handleSignOutClick}>
                            <span className="login-text" >Sign Out</span>
                        </div>
                </div>

          <div className="gradient-rectangle">
            <h2 className="feature-title">Deeptech innovation Assistant</h2>
            <div className="tab-horizontal-container">
                <div className="sign-in-disabled" onClick={navigateToFeatures} >
                    <a href="/#features" className="sign-in-text-disabled" >Inquire</a>
                </div>
                <div className="sign-in" >
                    <span className="sign-in-text" >Track</span>
                </div>
            </div>
          </div>
          <div className="feature-page-2">
          <div className="track-frame-main">
                <h2 className="inquire-header">Track anything</h2>
                <div className="helper-container">
                        <p className="inquire-text-1">
                            Stay at the frontier of technology development.
                        </p>
                        <div className="instructions-container">
                            <div className="instructions">
                                Instructions 
                            </div>
                            <div className="instructions-toggle dropdown-button" onClick={(e) => { e.stopPropagation(); setShowInstructions(!showInstructions); }}>
                                    {showInstructions ?  '▲' : '▼'}
                            </div>
                        </div>
                    </div>
                    {showInstructions && (
                    <div className="instruction-card-track">
                        <div className="inst-top-col">
                            <div className="inst-item_track">
                                <h1 className="inst-card-H">What is it for?</h1>
                                <div className="inst-item_b">
                                    <div className="inst-card-p">
                                        <ul className="inst-card-ul">
                                            <li>Track specific science innovations</li>
                                            <li>Surf new industrial use cases</li>
                                            <li>Patent alerts</li>
                                            <li>Monitor competitor</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="inst-card-F">Personalised newsfeeds</div>
                            </div>
                            <div className="inst-item_track">
                                <h1 className="inst-card-H">Step 1</h1>
                                <div className="inst-item_b">
                                    <img src={Tracker} alt="Tracker" style={{ width: '100%', height: 'auto' }}/>
                                </div>
                                <div className="inst-card-F">Set up you tracker</div>
                            </div>
                            <div className="inst-item_track">
                                <h1 className="inst-card-H">Step 2</h1>
                                <div className="inst-item_b">
                                    <img src={forYou} alt="For_You" style={{ width: '100%', height: 'auto' }}/>
                                </div>
                                <div className="inst-card-F">Receive curated news</div>
                            </div>
                        </div>
                    </div>
                    )}
                    
            </div>
            <div className="info" id="creditDisplay">Credits: {credits}</div>
            <div className="track-frame-mainb">
                <div className="track-for-you"></div>
                <div className="buttons-container">
                    <div className="track-button-1">
                        <h2 className="track-button-1-text">Add Email Push (coming soon)</h2>
                    </div>
                    <div className="track-button-2" onClick={openModal}>
                        <h2 className="track-button-2-text" >Tracker</h2>
                    </div>
                </div>
            </div>

             {/* <!-- Tracker Modal --> */}
            <div id="trackerModal" style={{display: showModal ? 'block' : 'none'}} class="backdrop-background" >
            <div className="customise-tracker-background">
                <div className="customise-tracker-container">
                    <h2 className="customise-tracker-font">Customize your Tracker</h2>
                    <div className="close-button" onClick={closeModal}>
                        <img src={Tracker_close} alt="Close" /> 
                    </div>
                </div>
                <div className="item-container-205">
                    <div className="item-container-100">
                        <div className="tracker-135">
                            <h2 className="item-font">Track</h2>
                        </div>
                        <div className="tracker-142">
                            <select 
                                className="tracker-96"
                                value={selectedOption1}
                                onChange={(e) => handleSelectionChange(1, e)}
                            >
                                <option value="Research">Research</option>
                                <option value="Patent">Patent</option>
                                <option value="Use Case">Use Case</option>
                                <option value="Researcher">Researcher</option>
                                <option value="Organization">Organization</option>
                                <option value="News">News</option>
                                <option value="Collaboration">Collaboration</option>
                            </select>
                            <span className="tracker-font-of">Of</span>
                                <input 
                                    type="text"
                                    className="tracker-94"
                                    value={currentBody1}
                                    onChange={(e) => setCurrentBody1(e.target.value)}
                                />
                        </div>
                        <div className="tracker-142">
                            <select 
                                className="tracker-96"
                                value={selectedOption2}
                                onChange={(e) => handleSelectionChange(2, e)}
                            >
                                <option value="Patent">Patent</option>
                                <option value="Research">Research</option>
                                <option value="Use Case">Use Case</option>
                                <option value="Researcher">Researcher</option>
                                <option value="Organization">Organization</option>
                                <option value="News">News</option>
                                <option value="Collaboration">Collaboration</option>
                            </select>
                            <span className="tracker-font-of">Of</span>
                                <input 
                                    type="text"
                                    className="tracker-94"
                                    value={currentBody2}
                                    onChange={(e) => setCurrentBody2(e.target.value)}
                                />
                        </div>
                        <div className="tracker-142">
                            <select 
                                className="tracker-96"
                                value={selectedOption3}
                                onChange={(e) => handleSelectionChange(3, e)}
                            >
                                <option value="Use Case">Use Case</option>
                                <option value="Research">Research</option>
                                <option value="Patent">Patent</option>
                                <option value="Researcher">Researcher</option>
                                <option value="Organization">Organization</option>
                                <option value="News">News</option>
                                <option value="Collaboration">Collaboration</option>
                            </select>
                            <span className="tracker-font-of">Of</span>
                                <input 
                                    type="text"
                                    className="tracker-94"
                                    value={currentBody3}
                                    onChange={(e) => setCurrentBody3(e.target.value)}
                                />
                        </div>
                        <div className="tracker-142">
                            <select 
                                className="tracker-96"
                                value={selectedOption4}
                                onChange={(e) => handleSelectionChange(4, e)}
                            >
                                <option value="Researcher">Researcher</option>
                                <option value="Research">Research</option>
                                <option value="Patent">Patent</option>
                                <option value="Use Case">Use Case</option>
                                
                                <option value="Organization">Organization</option>
                                <option value="News">News</option>
                                <option value="Collaboration">Collaboration</option>
                            </select>
                            <span className="tracker-font-of">Of</span>
                                <input 
                                    type="text"
                                    className="tracker-94"
                                    value={currentBody4}
                                    onChange={(e) => setCurrentBody4(e.target.value)}
                                />
                        </div>
                        <div className="tracker-142">
                            <select 
                                className="tracker-96"
                                value={selectedOption5}
                                onChange={(e) => handleSelectionChange(5, e)}
                            >
                                <option value="Organization">Organization</option>
                                <option value="Research">Research</option>
                                <option value="Patent">Patent</option>
                                <option value="Use Case">Use Case</option>
                                <option value="Researcher">Researcher</option>
                                
                                <option value="News">News</option>
                                <option value="Collaboration">Collaboration</option>
                            </select>
                            <span className="tracker-font-of">Of</span>
                                <input 
                                    type="text"
                                    className="tracker-94"
                                    value={currentBody5}
                                    onChange={(e) => setCurrentBody5(e.target.value)}
                                />
                        </div>
                        <div className="tracker-142">
                            <select 
                                className="tracker-96"
                                value={selectedOption6}
                                onChange={(e) => handleSelectionChange(6, e)}
                            >
                                <option value="News">News</option>
                                <option value="Research">Research</option>
                                <option value="Patent">Patent</option>
                                <option value="Use Case">Use Case</option>
                                <option value="Researcher">Researcher</option>
                                <option value="Organization">Organization</option>
                                <option value="Collaboration">Collaboration</option>
                            </select>
                            <span className="tracker-font-of">Of</span>
                                <input 
                                    type="text"
                                    className="tracker-94"
                                    value={currentBody6}
                                    onChange={(e) => setCurrentBody6(e.target.value)}
                                />
                        </div>
                        <div className="tracker-142">
                            <select 
                                className="tracker-96"
                                value={selectedOption7}
                                onChange={(e) => handleSelectionChange(7, e)}
                            >
                                <option value="Collaboration">Collaboration</option>
                                <option value="News">News</option>
                                <option value="Research">Research</option>
                                <option value="Patent">Patent</option>
                                <option value="Use Case">Use Case</option>
                                <option value="Researcher">Researcher</option>
                                <option value="Organization">Organization</option>
                                
                            </select>
                            <span className="tracker-font-of">Of</span>
                                <input 
                                    type="text"
                                    className="tracker-94"
                                    value={currentBody7}
                                    onChange={(e) => setCurrentBody7(e.target.value)}
                                />
                        </div>
                    </div>
                    <div className="item-container-98">
                        <div className="frequency-135">
                            <h2 className="item-font">Frequency(coming soon)</h2>
                        </div>
                        <div className="frequency-136">
                            <div className="frequency-96">
                                <h2 className="tracker-font">Weekly</h2>
                            </div>
                        </div>
                        <div className="frequency-136">
                            <div className="frequency-96">
                                <h2 className="tracker-font">Weekly</h2>
                            </div>
                        </div>
                        <div className="frequency-136">
                            <div className="frequency-96">
                                <h2 className="tracker-font">Weekly</h2>
                            </div>
                        </div>
                        <div className="frequency-136">
                            <div className="frequency-96">
                                <h2 className="tracker-font">Weekly</h2>
                            </div>
                        </div>
                        <div className="frequency-136">
                            <div className="frequency-96">
                                <h2 className="tracker-font">Weekly</h2>
                            </div>
                        </div>
                        <div className="frequency-136">
                            <div className="frequency-96">
                                <h2 className="tracker-font">Weekly</h2>
                            </div>
                        </div>
                        <div className="frequency-136">
                            <div className="frequency-96">
                                <h2 className="tracker-font">Weekly</h2>
                            </div>
                        </div>
                    </div>
                    <div className="bin-column"></div>
                </div>
                <div className="save-bar">
                    <div className="save-button" onClick={onResearchSubmit}>
                        <span className="save-button-text">Save</span>
                    </div>

                </div>
            </div>
            </div>
            <div className="track-frame-154">
                <div className="vector-68"></div>
                <div className="track-frame-150">
                    {researchResults.map((result, index) => (
                                <div key={result.id} className="track-frame-15-card" 
                                >
                                    <div className="close-button" onClick={() => handleDelete(result.id)}>
                                        <img src={Tracker_close} alt="Close" />
                                    </div>
                                    <div className="track-frame-42"> 
                                        <div className="track-frame-207">
                                            <div className="track-frame-43">
                                                <h2 className="track-text-header">Research</h2>
                                            </div>
                                            <h2 className="track-text-date">{formatDate(result.date)}</h2>
                                        </div>
                                        <h2 className="track-title-font">{result.title}</h2>
                                        <div className="track-body">
                                            <section className="track-body-font">
                                                {result.body}
                                                <span className="track-ref-font"> (Ref here)</span>
                                            </section>
                                            
                                        </div>
                                        
                                    </div>
                                </div>  
                    ))}
                    {useCaseResults.map((result, index) => (
                    <div key={result.id} className="track-frame-15-card">
                        <div className="close-button" onClick={() => handleDelete(result.id)}>
      <img src={Tracker_close} alt="Close" />
    </div>
                        <div className="track-frame-42"> 
                            <div className="track-frame-207">
                                <div className="track-frame-43">
                                    <h2 className="track-text-header">Use Case</h2>
                                </div>
                                <h2 className="track-text-date">{formatDate(result.date)}</h2>
                            </div>
                            <h2 className="track-title-font">{result.title}</h2>
                            <div className="track-body">
                                <section className="track-body-font">
                                    {result.body}
                                    <span className="track-ref-font"> ({result.ref})</span>
                                </section>
                            </div>
                            
                        </div>
                    </div>
                    ))}
                    {patentResults.map((result, index) => (
                    <div key={result.id} className="track-frame-15-card">
                        <div className="close-button" onClick={() => handleDelete(result.id)}>
      <img src={Tracker_close} alt="Close" />
    </div>
                        <div className="track-frame-42"> 
                            <div className="track-frame-207">
                                <div className="track-frame-43">
                                    <h2 className="track-text-header">Patent</h2>
                                </div>
                                <h2 className="track-text-date">{formatDate(result.date)}</h2>
                            </div>
                            <h2 className="track-title-font">{result.title}</h2>
                            <div className="track-body">
                                <section className="track-body-font">
                                    {result.body}
                                    <span className="track-ref-font">({result.ref})</span>
                                </section>
                            </div>
                            
                        </div>
                    </div>
                    ))}
                </div>

                <div className="track-frame-150">
                    {rchResults.map((result, index) => (
                    <div key={result.id} className="track-frame-15-card">
                        <div className="close-button" onClick={() => handleDelete(result.id)}>
      <img src={Tracker_close} alt="Close" />
    </div>
                        <div className="track-frame-42"> 
                            <div className="track-frame-207">
                                <div className="track-frame-43">
                                    <h2 className="track-text-header">Researcher</h2>
                                </div>
                                <h2 className="track-text-date">{formatDate(result.date)}</h2>
                            </div>
                            <h2 className="track-title-font">{result.title}</h2>
                            <div className="track-body">
                                <section className="track-body-font">
                                    {result.body}
                                    <span className="track-ref-font"></span>
                                </section>
                                
                            </div>
                            
                        </div>
                    </div>
                    ))}
                    {newsResults.map((result, index) => (
                    <div key={result.id} className="track-frame-15-card">
                        <div className="close-button" onClick={() => handleDelete(result.id)}>
      <img src={Tracker_close} alt="Close" />
    </div>
                        <div className="track-frame-42"> 
                            <div className="track-frame-207">
                                <div className="track-frame-43">
                                    <h2 className="track-text-header">News</h2>
                                </div>
                                <h2 className="track-text-date">{formatDate(result.date)}</h2>
                            </div>
                            <h2 className="track-title-font">{result.title}</h2>
                            <div className="track-body">
                                <section className="track-body-font">
                                    {result.body}
                                    <span className="track-ref-font"> ({result.ref})</span>
                                </section>
                            </div>
                            
                        </div>
                    </div>
                    ))}
                    {orgResults.map((result, index) => (
                    <div key={result.id} className="track-frame-15-card">
                        <div className="close-button" onClick={() => handleDelete(result.id)}>
      <img src={Tracker_close} alt="Close" />
    </div>
                        <div className="track-frame-42"> 
                            <div className="track-frame-207">
                                <div className="track-frame-43">
                                    <h2 className="track-text-header">Organization</h2>
                                </div>
                                <h2 className="track-text-date">{formatDate(result.date)}</h2>
                            </div>
                            <h2 className="track-title-font">{result.title}</h2>
                            <div className="track-body">
                                <section className="track-body-font">
                                    {result.body}
                                    <span className="track-ref-font"> ({result.ref})</span>
                                </section>
                            </div>
                            
                        </div>
                    </div>
                    ))}
                    {collabResults.map((result, index) => (
                    <div key={result.id} className="track-frame-15-card">
                        <div className="close-button" onClick={() => handleDelete(result.id)}>
      <img src={Tracker_close} alt="Close" />
    </div>
                        <div className="track-frame-42"> 
                            <div className="track-frame-207">
                                <div className="track-frame-43">
                                    <h2 className="track-text-header">Collaboration</h2>
                                </div>
                                <h2 className="track-text-date">{formatDate(result.date)}</h2>
                            </div>
                            <h2 className="track-title-font">{result.title}</h2>
                            <div className="track-body">
                                <section className="track-body-font">
                                    {result.body}
                                    <span className="track-ref-font"> ({result.ref})</span>
                                </section>
                            </div>
                            
                        </div>
                    </div>
                    ))}
                </div>
                <div 
                    style={{
                        display: 'flex', // Use flexbox to lay out children in a row
                        justifyContent: 'center', // Center children horizontally
                        alignItems: 'center', // Align children vertically in the center
                        gap: '20px', // Space between the elements
                        
                    }}
                >
                    <div 
                        style={{
                            color: 'var(--Primary, #4476F1)',
                            fontFamily: 'Inter',
                            fontSize: '22px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            textDecorationLine: 'underline',
                            cursor: 'pointer'
                        }}
                        onClick={() => setShowPreviousContent(!showPreviousContent)}
                    >
                        See Previous Content
                    </div>
                    <button 
                        style={{
                            fontFamily: 'Inter',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            color: 'var(--Primary, #4476F1)',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            backgroundColor: 'transparent',
                            border: 'none',
                            padding: '0',
                            flex: '1 0 0',
                        }}
                        onClick={deleteAllResults}
                    >
                        Clear All
                    </button>
                </div>
                {showPreviousContent && (
                    <div className="track-frame-154">
                        <div className="track-frame-150">
                            {Array.isArray(savedResearchResults) && savedResearchResults.length > 0 ? (
                                savedResearchResults.map((result, index) => (
                                    result && (
                                    <div key={index} className="track-frame-15-card">
                                        <div className="close-button" onClick={() => deleteResearchResult(result.id)}>
          <img src={Tracker_close} alt="Close" />
        </div>
                                        <div className="track-frame-42">
                                            <div className="track-frame-207">
                                                <div className="track-frame-43">
                                                    <h2 className="track-text-header">Research</h2>
                                                </div>
                                                <h2 className="track-text-date">{formatDate(new Date(result.date))}</h2>
                                            </div>
                                            <h2 className="track-title-font">{result.title}</h2>
                                            <div className="track-body">
                                                <section className="track-body-font">
                                                    {result.body}
                                                    <span className="track-ref-font"> (Ref here)</span>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                ))
                            ) : (
                                <div></div>
                            )}
                            {Array.isArray(savedUseCaseResults) && savedUseCaseResults.length > 0 ? (
                                savedUseCaseResults.map((result, index) => (
                                    result && (
                                    <div key={index} className="track-frame-15-card">
                                        <div className="close-button" onClick={() => deleteUseCaseResult(result.id)}>
          <img src={Tracker_close} alt="Close" />
        </div>
                                        <div className="track-frame-42">
                                            <div className="track-frame-207">
                                                <div className="track-frame-43">
                                                    <h2 className="track-text-header">Researcher</h2>
                                                </div>
                                                <h2 className="track-text-date">{formatDate(new Date(result.date))}</h2>
                                            </div>
                                            <h2 className="track-title-font">{result.title}</h2>
                                            <div className="track-body">
                                                <section className="track-body-font">
                                                    {result.body}
                                                    <span className="track-ref-font"> ({result.ref})</span>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                ))
                            ) : (
                                <div></div>
                            )}
                            {Array.isArray(savedResearcherResults) && savedResearcherResults.length > 0 ? (
                                savedResearcherResults.map((result, index) => (
                                    result && (
                                    <div key={index} className="track-frame-15-card">
                                        <div className="close-button" onClick={() =>deleteResearcherResult(result.id)}>
          <img src={Tracker_close} alt="Close" />
        </div>
                                        <div className="track-frame-42">
                                            <div className="track-frame-207">
                                                <div className="track-frame-43">
                                                    <h2 className="track-text-header">Use Case</h2>
                                                </div>
                                                <h2 className="track-text-date">{formatDate(new Date(result.date))}</h2>
                                            </div>
                                            <h2 className="track-title-font">{result.title}</h2>
                                            <div className="track-body">
                                                <section className="track-body-font">
                                                    {result.body}
                                                    <span className="track-ref-font"> </span>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                ))
                            ) : (
                                <div></div>
                            )}
                        </div>
                    
                        <div className="track-frame-150">
                            {Array.isArray(savedNewsResults) && savedNewsResults.length > 0 ? (
                                savedNewsResults.map((result, index) => (
                                    result && (
                                    <div key={index} className="track-frame-15-card">
                                        <div className="close-button" onClick={() => deleteNewsResult(result.id)}>
          <img src={Tracker_close} alt="Close" />
        </div>
                                        <div className="track-frame-42">
                                            <div className="track-frame-207">
                                                <div className="track-frame-43">
                                                    <h2 className="track-text-header">News</h2>
                                                </div>
                                                <h2 className="track-text-date">{formatDate(new Date(result.date))}</h2>
                                            </div>
                                            <h2 className="track-title-font">{result.title}</h2>
                                            <div className="track-body">
                                                <section className="track-body-font">
                                                    {result.body}
                                                    <span className="track-ref-font"> ({result.ref})</span>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                ))
                            ) : (
                                <div></div>
                            )}
                            {Array.isArray(savedPatentResults) && savedPatentResults.length > 0 ? (
                                savedPatentResults.map((result, index) => (
                                    result && (
                                    <div key={index} className="track-frame-15-card">
                                        <div className="close-button" onClick={() => deletePatentResult(result.id)}>
          <img src={Tracker_close} alt="Close" />
        </div>
                                        <div className="track-frame-42">
                                            <div className="track-frame-207">
                                                <div className="track-frame-43">
                                                    <h2 className="track-text-header">Patent</h2>
                                                </div>
                                                <h2 className="track-text-date">{formatDate(new Date(result.date))}</h2>
                                            </div>
                                            <h2 className="track-title-font">{result.title}</h2>
                                            <div className="track-body">
                                                <section className="track-body-font">
                                                    {result.body}
                                                    <span className="track-ref-font"> ({result.ref})</span>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                ))
                            ) : (
                                <div></div>
                            )}
                            {Array.isArray(savedOrgResults) && savedOrgResults.length > 0 ? (
                                savedOrgResults.map((result, index) => (
                                    result && (
                                    <div key={index} className="track-frame-15-card">
                                        <div className="close-button" onClick={() => deleteOrgResult(result.id)}>
          <img src={Tracker_close} alt="Close" />
        </div>
                                        <div className="track-frame-42">
                                            <div className="track-frame-207">
                                                <div className="track-frame-43">
                                                    <h2 className="track-text-header">Organization</h2>
                                                </div>
                                                <h2 className="track-text-date">{formatDate(new Date(result.date))}</h2>
                                            </div>
                                            <h2 className="track-title-font">{result.title}</h2>
                                            <div className="track-body">
                                                <section className="track-body-font">
                                                    {result.body}
                                                    <span className="track-ref-font"> ({result.ref})</span>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                ))
                            ) : (
                                <div></div>
                            )}
                            {Array.isArray(savedCollabResults) && savedCollabResults.length > 0 ? (
                                savedCollabResults.map((result, index) => (
                                    result && (
                                    <div key={index} className="track-frame-15-card">
                                        <div className="close-button" onClick={() => deleteCollabResult(result.id)}>
          <img src={Tracker_close} alt="Close" />
        </div>
                                        <div className="track-frame-42">
                                            <div className="track-frame-207">
                                                <div className="track-frame-43">
                                                    <h2 className="track-text-header">Collaboration</h2>
                                                </div>
                                                <h2 className="track-text-date">{formatDate(new Date(result.date))}</h2>
                                            </div>
                                            <h2 className="track-title-font">{result.title}</h2>
                                            <div className="track-body">
                                                <section className="track-body-font">
                                                    {result.body}
                                                    <span className="track-ref-font"> ({result.ref})</span>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                ))
                            ) : (
                                <div></div>
                            )}
                        </div>

                     
                    </div>
                )}
            </div>
            
          </div>

          <div className="comment">
            <div className="comment-container">
                <div className="comment-text">Leave a comment</div>
            </div>
            <div className="message-frame">
                <div className="message-header">Message *</div>
                <textarea 
                    className="message-box" 
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type your message here..."
                />
            </div>
            <div className={buttonColor} onClick={handleSubmit} >
                <div className="feature-text-active">{buttonText}</div>
            </div>
            <div className="contact-text-combined">
                Or reach out to us at  
                <a href="mailto:contact@tylo.ai" className="contact-email"> contact@tylo.ai</a>
            </div>

          </div>
          <div className="all-container">
                <div className="all-rights-text">2024 Tylo AI Ltd. All rights reserved. </div>   
                <div className="all-rights-highlight" onClick={handleTOUClick}>
                    Terms of use  
                </div>
                <div className="all-rights-highlight" onClick={handlePrivacyPolicyClick}>
                    Privacy Policy 
                </div> 
                <a href="https://www.linkedin.com/company/tyloai/" target="_blank" rel="noopener noreferrer">
                <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="https://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_2801_871)">
    <path d="M29.4609 0.75H9.53906C4.685 0.75 0.75 4.685 0.75 9.53906V29.4609C0.75 34.315 4.685 38.25 9.53906 38.25H29.4609C34.315 38.25 38.25 34.315 38.25 29.4609V9.53906C38.25 4.685 34.315 0.75 29.4609 0.75Z" fill="white"/>
    <path d="M29.4609 0.75H9.53906C4.685 0.75 0.75 4.685 0.75 9.53906V29.4609C0.75 34.315 4.685 38.25 9.53906 38.25H29.4609C34.315 38.25 38.25 34.315 38.25 29.4609V9.53906C38.25 4.685 34.315 0.75 29.4609 0.75Z" fill="#4476F1"/>
    <path d="M27.8079 32.6375H32.0955C32.2508 32.6375 32.3998 32.5757 32.5097 32.4659C32.6196 32.356 32.6814 32.207 32.6814 32.0517L32.6836 22.9928C32.6836 18.258 31.6633 14.6186 26.1302 14.6186C24.0268 14.5403 22.0433 15.6246 20.9729 17.4352C20.9677 17.444 20.9597 17.4508 20.9502 17.4546C20.9407 17.4585 20.9303 17.4591 20.9204 17.4564C20.9105 17.4538 20.9018 17.4479 20.8955 17.4398C20.8893 17.4317 20.8858 17.4218 20.8857 17.4116V15.6416C20.8857 15.4862 20.824 15.3372 20.7141 15.2273C20.6042 15.1174 20.4552 15.0557 20.2998 15.0557H16.2309C16.0755 15.0557 15.9265 15.1174 15.8166 15.2273C15.7067 15.3372 15.645 15.4862 15.645 15.6416V32.0508C15.645 32.2062 15.7067 32.3552 15.8166 32.4651C15.9265 32.575 16.0755 32.6367 16.2309 32.6367H20.5182C20.6736 32.6367 20.8226 32.575 20.9325 32.4651C21.0424 32.3552 21.1042 32.2062 21.1042 32.0508V23.9395C21.1042 21.646 21.5392 19.4249 24.3826 19.4249C27.1856 19.4249 27.2219 22.0493 27.2219 24.088V32.0515C27.2219 32.2069 27.2837 32.3559 27.3935 32.4658C27.5034 32.5757 27.6525 32.6375 27.8079 32.6375ZM6.31641 9.48457C6.31641 11.2225 7.74712 12.6524 9.48516 12.6524C11.2228 12.6523 12.6526 11.2214 12.6526 9.48384C12.6523 7.74624 11.2223 6.31641 9.48457 6.31641C7.74639 6.31641 6.31641 7.74668 6.31641 9.48457ZM7.33579 32.6375H11.6288C11.7842 32.6375 11.9332 32.5757 12.0431 32.4658C12.153 32.3559 12.2147 32.2069 12.2147 32.0515V15.6416C12.2147 15.4862 12.153 15.3372 12.0431 15.2273C11.9332 15.1174 11.7842 15.0557 11.6288 15.0557H7.33579C7.18039 15.0557 7.03136 15.1174 6.92147 15.2273C6.81159 15.3372 6.74985 15.4862 6.74985 15.6416V32.0515C6.74985 32.2069 6.81159 32.3559 6.92147 32.4658C7.03136 32.5757 7.18039 32.6375 7.33579 32.6375Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_2801_871">
      <rect width="37.5" height="37.5" fill="white" transform="translate(0.75 0.75)"/>
    </clipPath>
  </defs>
</svg>
</a>
            </div>


        </div>
    );
}

export default Track;