import React, { useEffect, useState} from "react";
// import ReactHtmlParser from 'html-react-parser'; 
// import ReactDOM from "react-dom";
import Tylo_icon from "../../assets/images/Tylo_logo.svg";
// import Tracker_close from "../../assets/images/tracker-close.svg";
// import Dropdown_open from "../../assets/images/Dropdown_open.svg" ;
// import Dropdown_closed from "../../assets/images/Dropdown_closed.svg";
import "../features/features.scss";
import { gapi } from "gapi-script";
import axios from "axios";
import {useNavigate } from "react-router-dom";
import useQuery from '../../hooks/useQuery';
import parse from 'html-react-parser';
// At the top of your index.js or index.tsx file
// import { setToken } from "../../services/userService";
// import { createClient } from '@supabase/supabase-js';

function Inquire() {
    console.log('Inquire unlocked component rendering')
    const [answerText, setAnswerText] = useState('');
    const googleProfileImageUrl = localStorage.getItem("googleProfileImageUrl");
    const navigate = useNavigate();
    
    const [supportEvidence, setSupportEvidence] = useState([]);
    const [referenceSources, setReferenceSources] = useState([]);
    const [relevanceDisplay, setRelevanceDisplay] = useState([]);
    // const [jsonData, setJsonData] = useState([]);
    const [itemCount, setItemCount] = useState([]);
    const [isDyslexiaFontEnabled, setIsDyslexiaFontEnabled] = useState(false);
    const [isRequesting, setIsRequesting] = useState(false);
    const [progress, setProgress] = useState(0);
    const [buttonText, setButtonText] = useState('Submit');
    const [buttonColor, setButtonColor] = useState('submit-button');
    // const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState('');
    const token = localStorage.getItem('token');
    // const email = localStorage.getItem('tyloEmail');
    const [credits, setCredits] = useState('');
    // const [referralCode, setReferralCode] = useState('');
    const query = useQuery();

    const [showInstructions, setShowInstructions] = useState(false);
    // let progressInterval;


    // Function to handle click event
    const navigateToFeatures = () => {
        navigate('/track'); // Navigate to /#features
    };
    const navigateToSettings = () => {
        navigate('/settings'); // Navigate to /#features
    };


    const [selectedOption1, setSelectedOption1] = useState('Research');
    // const [currentBody1, setCurrentBody1] = useState('');

    /*********  Tracker stuff ********************/
    // const [researchHeader, setResearchHeader] = useState('');
    // const [researchBody, setResearchBody] = useState('');
    // const [patentHeader, setPatentHeader] = useState('');
    // const [patentBody, setPatentBody] = useState('');
    // const [useCaseHeader, setUseCaseHeader] = useState('');
    // const [useCaseBody, setUseCaseBody] = useState('');
    // const [researcherHeader, setResearcherHeader] = useState('');
    // const [researcherBody, setResearcherBody] = useState('');
    // const [organizationHeader, setOrganizationHeader] = useState('');
    // const [organizationBody, setOrganizationBody] = useState('');
    // const [newsHeader, setNewsHeader] = useState('');
    // const [newsBody, setNewsBody] = useState('');

    // const [showMore, setShowMore] = useState(false);

    // const [trackTitleResearch, setTrackTitleResearch] = useState('Title Here');
    // const [bodyResearch, setBodyResearch] = useState('Body Here');
    // const [titleUseCase, setTitleUseCase] = useState('Title here');
    // const [bodyUseCase, setBodyUseCase] = useState('Body here');
    // const [titleNews,setTitleNews] = useState('Title Here');
    // const [bodyNews, setBodyNews] = useState('Body Here');
    // const [titleResearcher, setTitleResearcher] = useState('Title here');
    // const [bodyResearcher, setBodyResearcher] = useState('Body here');

    // const supabase = createClient('https://cyurqurlcxlyihpxzxyk.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImN5dXJxdXJsY3hseWlocHh6eHlrIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODgxNDEwNDQsImV4cCI6MjAwMzcxNzA0NH0.CdUPlN6gZQ6aA4kFiZuBXoAc4W_zXj4ywH0oaDAV70o')



    
    // const updateCreditsDisplay = (credits) => {
    //     document.getElementById("creditDisplay").innerText = `Credits: ${credits}`;
    // }
    
    // const deductCredits = (amount) => {
    //     let currentCredits = credits;
    //     currentCredits = parseFloat((currentCredits - amount).toFixed(2)); 
    //     // localStorage.setItem("tyloCredits", currentCredits.toString());
    //     updateCreditsDisplay(currentCredits);
    //     updateBackendCredits(currentCredits);
    //     // console.log(credits)
    // }

    // const updateBackendCredits = async (newCredits) => {
    //     try {
    //         const token = localStorage.getItem('token'); // Assuming you're storing the token in localStorage
    //         const response = await axios.post("https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/updateCredits", {
    //             credits: newCredits, // Sending the new credits amount to the backend
    //         }, {
    //             headers: {
    //                 'Authorization': `Bearer ${token}`, // Use the JWT token from localStorage
    //             }
    //         });
    
    //         // console.log("Response data:", response.data);
    
    //         // Update your frontend state to reflect the new credits
    //         setCredits(response.data.credits);
    //         // Optionally update local storage or other caches if necessary
    //     } catch (error) {
    //         console.error('Error updating credits:', error);
    //         // Handle error (show feedback to the user, etc.)
    //         // If you want to specifically handle HTTP error statuses, you can check error.response.status
    //     }
    // };
    

    


    useEffect(() => {
        const refCode = query.get('ref');
        if (refCode) {
            // setReferralCode(refCode);
            // console.log('refCode', refCode)
            axios.post('https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/generateReferral', {
                referralCode: refCode,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then((response) => {
                console.log('Referral user details:', response.data);
                // You can use the referral user details if needed
            })
            .catch((error) => {
                console.error('Error fetching referral details:', error);
            });
        }

        // Load the Google API client library
        const script = document.createElement("script");
        script.src = "https://apis.google.com/js/api.js";
        script.onload = () => {
          // Initialize the Google API client library
          gapi.load("auth2", () => {
            gapi.auth2.init({
              client_id:
                "584832623015-02ioa5kbjqp9agd30pdiifln0bhb5trb.apps.googleusercontent.com",
              scope: "profile email",
              redirect_uri: "https://tylo.ai/", 
              cookiepolicy: "single_host_origin",
            });
          });
        };
        document.body.appendChild(script);

        fetch("https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/profile", {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          })
          .then(response => response.json())
          .then(data => {
            setCredits(data.credits);
            // console.log('credits backend:', data.credits)
        })
        .catch(error => console.error('Error fetching user data:', error));
        
        // let timer;
        // if (isRequesting && progress < 100) {
        //   timer = setTimeout(() => {
        //     setProgress(progress + 10);
        //   }, 20000);
        // } else if (progress >= 100) {
        //   setIsRequesting(false);
        //   setProgress(0); // Reset progress for next time
        // }
    
        // return () => clearTimeout(timer);
        
    }, [token, query]);

    const handlePrivacyPolicyClick = () => {
        window.open(`${window.location.origin}/#privacy`, '_blank');
    };
    const handleTOUClick = () => {
        window.open(`${window.location.origin}/#termsofuse`, '_blank');
    }
    

      const handleSignOutClick = () => {
        // Sign out from your application
        // This might involve removing the user's session or token from local storage
        localStorage.removeItem('token'); // For example, if you store a session token

        // If using Google sign-in and you want to sign the user out of Google as well
        const auth2 = gapi.auth2.getAuthInstance();
        if (auth2 != null) {
            auth2.signOut().then(auth2.disconnect().then(() => {
                console.log('User signed out of Google.');
                navigate('/#');
            }));
        }

        navigate('/#'); // -1 takes you one page back in the history
        // Redirect the user to the sign-in page or another appropriate page
        // This depends on your routing setup, for example using react-router
        // navigate('/signin'); // Example using react-router
    };

    const getAppState = () => {
        var textareaValue = document.querySelector('.inquire-textbox').value;
        const userId = localStorage.getItem('tyloEmail'); 
        return {
            userId,
            textareaValue,
            answerText,
          // Add other state elements as needed
        };
      };

    const handleSubmit = () => {
        const token = localStorage.getItem('token'); // Ensure you're getting the auth token correctly
        const appState = getAppState(); 
        

        setButtonText('Submitted');
        setButtonColor('submit-button-green');
    
        // Replace the URL with your actual endpoint
        const url = "https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/message";
    
        axios.post(url, {
          message,
          appState: JSON.stringify(appState),
        }, {
          headers: {
            "Authorization": `Bearer ${token}`, // Adjust this according to your token auth setup
            "Content-Type": "application/json",
          },
        }).then(response => {
        //   console.log('Message submitted:', response.data);
          setMessage(''); // Clear the message input after successful submission
        }).catch(error => {
          console.error('Error submitting message:', error);
        });

        setTimeout(() => {
            setButtonText('Submit');
            setButtonColor('submit-button'); // Revert to the initial class
        }, 3000);
      };

    function formatUrlDisplayName(url) {
        try {
            const newUrl = new URL(url);
            // Check if the URL's hostname starts with 'www.' or if it's a typical URL with 'https://'
            if (newUrl.hostname.startsWith('www.')) {
                return newUrl.hostname.substring(4); // Remove 'www.' from the start
            } else if (newUrl.protocol === "https:") {
                return newUrl.hostname; // Use the full hostname
            } else {
                return url; // Return the original URL if it's not standard
            }
        } catch (error) {
            return url; // Return the original URL in case of an exception (invalid URL)
        }
    }
    

    const handleFilterChange = (event) => {
        // Get the selected value
        const selectedFilter = event.target.value;
    
        // Call the function to filter the data
        filterData(selectedFilter);
    };

    const filterData = (filterCriteria) => {
        let filteredData = [];

        const sortedData = itemCount.sort((a, b) => b.relevance - a.relevance);


    
        switch (filterCriteria) {
            case 'all':
                filteredData = sortedData.slice(0, itemCount.length); // Assuming 'data' is your original data array
                break;
            case 'top10':
                filteredData = sortedData.slice(0, 10); // Get the first 10 items
                break;
            case 'top20':
                filteredData = sortedData.slice(0, 20); // Get the first 20 items
                break;
            default:
                filteredData = sortedData.slice(0, 10); // Default to showing all
        }
    
        updateDisplay(filteredData);
        
    };


    const handleChange = (event) => {
        setSelectedOption1(event.target.value);  // Just update the state here
    };

    const handleButtonClick = () => {
        handleInquireClick(selectedOption1)

        // switch (selectedOption1) {
        //     case "General Inquiry":
        //         handleInquireClick();
        //         break;
        //     case "Key findings":
        //         handleKeyFindings();
        //         break;
        //     case "SWOT":
        //         handleSWOT();
        //         break;
        //     case "Evidenced based opinion":
        //         handleEvidencedBasedOpinion();
        //         break;
        //     case "Patent":
        //         handlePatentWork();
        //         break;
        //     default:
        //         handleInquireClick();
        //         console.log("No valid option selected or action required");
        // }
    };


    
    const handleDyslexiaFontToggle = (event) => {
        setIsDyslexiaFontEnabled(event.target.checked);
    };

    const updateDisplay = (filteredData) => {
        // Your logic to update the UI
        // This might involve clearing the existing displayed data
        // and then iterating over 'filteredData' to display each item

        // Clear existing data
        setSupportEvidence([]);
        setReferenceSources([]);

        // const minRelevance = Math.min(...filteredData.map(item => item.relevance));
        //         const maxRelevance = Math.max(...filteredData.map(item => item.relevance));


        // Function to calculate color based on relevance
        const getColorForRelevance = (relevance) => {
            if (relevance >= 0.55) {
                return "rgb(0, 225, 0)"; // Green
            } else if (relevance >= 0.5 && relevance < 0.55) {
                return "rgb(255, 225, 0)"; // Yellow
            } else {
                return "rgb(255, 0, 0)"; // Red
            }
        };

        let supportData = [];
        let referenceData = [];
        let relevanceDisplay = [];

        filteredData.forEach((item, index) => {
            let className = index % 2 === 0 ? 'support-frame-94' : 'support-frame-135-b';
            const color = getColorForRelevance(item.relevance || item.score);
            const relevanceStyle = {
                backgroundColor: color
            };
                    
            supportData.push({ content: item.paragraph || item.content, className });
            
            // const linkDisplayName = formatUrlDisplayName(item.article_url);
            
            referenceData.push({ title: item.title, url: item.article_url || item.url, className });
            
            relevanceDisplay.push({ style: relevanceStyle, className });
        });

        setSupportEvidence(supportData);
        setReferenceSources(referenceData);
        setRelevanceDisplay(relevanceDisplay);
    };

    const updateStatusText = () => {
        const messages = [
            "Initiating data gathering protocols...",
            "Compiling raw data from diverse sources...",
            "Analyzing information through advanced algorithms...",
            "Synthesizing insights from complex datasets...",
            "Applying machine learning techniques for deeper analysis...",
            "Cross-referencing findings against existing knowledge bases...",
            "Finalizing comprehensive results presentation...",
            "Almost there, preparing to display findings..."
        ];
        let counter = 0;
    
        const messageInterval = setInterval(() => {
            setAnswerText(messages[counter]);
            counter++;
            if (counter === messages.length) {
                clearInterval(messageInterval);
            }
        }, 4000); // Update text every 2 seconds
    
        return messageInterval;
    };
    
    

    const handleInquireClick = async (prompt) => {
        setIsRequesting(true);
        
        const statusInterval = updateStatusText();
        setProgress(10);

        
        let progressInterval;
          // Simulate progress for the loading bar
        const incrementProgress = (increment) => {
            setProgress(prevProgress => {
                const nextProgress = prevProgress + increment;
                if (nextProgress >= 100) {
                    clearInterval(progressInterval); // Ensure clearing interval
                    return 100;
                }
                return nextProgress;
            });
        };
        progressInterval = setInterval(() => {
            incrementProgress(10); // Increment by 10 every 3 seconds.
            setProgress(progress => {
                if (progress >= 60) {
                    clearInterval(progressInterval); // Clear the interval once progress reaches 60.
                }
                return progress;
            });
        }, 3000);
        

        // setJsonData([]);
        // let finalTexts = "";
        // let texts = "";
        // Get the value from the textarea
        var textareaValue = document.querySelector('.inquire-textbox').value;

        const url = 'https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/inquiry';

        const body = {
            question: textareaValue,
            
        };

        const promptType = prompt;

        const params = new URLSearchParams({ promptType });

        const response = await fetch(`${url}?${params.toString()}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // If you have an authentication token
            },
            body: JSON.stringify(body),
        });


        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log('Frontend Response Data:', data.supporting_evidence);
        console.log("token", token)
        setAnswerText(data.message)
        clearInterval(statusInterval);
        setIsRequesting(false);
        clearInterval(progressInterval);

        setItemCount(data.supporting_evidence)
        updateDisplay(data.supporting_evidence)
    
        return data;



        // // Encode the textarea content to be used in a URL
        // var encodedValue = encodeURIComponent(textareaValue);

        // const requestBody = {
        //     api_key: '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', // Replace with your actual API key
        //     query: `${encodedValue}`,
        //     search_depth: 'advanced',
        //     include_raw_content: true,
        //     max_results: 10
        //   };

        // // Construct the URL with the textarea content
        // var url1 = `https://archive.tylo.ai/query_arxiv/arxiv?question=${encodedValue}`;
        // var url2 = `https://archive.tylo.ai/query_pubmed/pubmed?question=${encodedValue}`;
        // // var url3 = `https://archive.tylo.ai/google_query/google?question=${encodedValue}`;
        // const tavilyUrl = `https://api.tavily.com/search`;
        // // Send the request to the server
        // Promise.all([fetch(url1), fetch(url2), /*fetch(url3),*/ fetch(tavilyUrl, {
        //     method: 'POST',
        //     headers: {
        //       'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(requestBody)
        //   })])
        //     .then(responses => Promise.all(responses.map(res => {
        //         incrementProgress(10);
        //         if (res.ok) return res.json();
        //         throw new Error('Network response was not ok.');
        //     })))
        //     .then(dataArray => {
        //         incrementProgress(10);
        //         clearInterval(statusInterval); // Stop updating status text
        //         setAnswerText("Compiling the most relevant information...");

        //         // const data = dataArray.flat(); // Combine arrays if both URLs return arrays

        //         // Process results from url1, url2, and url3
        //         const otherData = dataArray.filter(data => !data.results);
        //         const flatOtherData = otherData.flat();
        //         flatOtherData.sort((a, b) => b.relevance - a.relevance);
        //         const top30OtherItems = flatOtherData.slice(0, 30);

        //         // const tavilyData = dataArray.find(data => data.results !== undefined);

        //         // Process results from tavilyUrl
        //         const tavilyData = dataArray.find(data => data.results !== undefined);
        //         let top30TavilyItems = [];
        //         if (tavilyData && Array.isArray(tavilyData.results)) {
        //             tavilyData.results.sort((a, b) => b.score - a.score);
        //             top30TavilyItems = tavilyData.results.slice(0, 30);
        //         }


        //         // Combine both results
        //         const combinedResults = [...top30OtherItems, ...top30TavilyItems];
        //         console.log("fetch data",tavilyData);
        //         console.log("Number of results:", tavilyData.results.length);

        //         // const minRelevance = Math.min(...data.map(item => item.relevance));
        //         // const maxRelevance = Math.max(...data.map(item => item.relevance));

        //         // Limit to the top 30 items after combining
        //         const top30CombinedItems = combinedResults.slice(0, 30);
               
        //         // setJsonData(data);
        //         setItemCount(combinedResults)
                
        //         // Sort the combined results based on a unified criterion (e.g., score or relevance)
        //         combinedResults.sort((a, b) => {
        //             const scoreA = a.score || a.relevance;
        //             const scoreB = b.score || b.relevance;
        //             return scoreB - scoreA;
        //         });



        //         // data.sort((a, b) => b.relevance - a.relevance);
        //         // tavilyData.results.sort((a, b) => b.score - a.score);
        //         // data.sort((a, b) => b.results.score - a.results.score)

        //         // Limit to the most relevant 30 items
        //         // const top30Items = data.slice(0, 30);
        //         // const top30Items = tavilyData.results.slice(0, 30);

        //         // texts = top30Items.map((item, idx) => `${item.paragraph}`);
        //         // texts2 = top30Items.map((item, idx) => `${item.results.raw_content}`);
        //         // finalTexts = texts2.join("\n");
        //         // finalTexts = top30Items.map((item) => item.raw_content).join("\n");
        //         // console.log("context", finalTexts )
        //         const maxCharacters = 30000;

        //         finalTexts = top30CombinedItems.map((item) => {
        //             const rawContent = item.raw_content ? item.raw_content : '';
        //             const paragraph = item.paragraph ? item.paragraph : '';
        //             return rawContent + '\n' + paragraph;
        //         }).join("\n");
        //         console.log("context", finalTexts);
        //         console.log("context length", finalTexts.length);
        //         // Truncate if necessary
        //         if (finalTexts.length > maxCharacters) {
        //             finalTexts = finalTexts.slice(0, maxCharacters);
        //             console.log("new context", finalTexts);
        //             console.log("new context length", finalTexts.length);
        //         }
 

        //         // const sortedData = data.sort((a, b) => b.relevance - a.relevance);
        //         // const filteredData = sortedData.slice(0, data.length);
        //         // const filteredData = tavilyData.results.slice(0, tavilyData.results.length);
                
        //         const filteredData = combinedResults.slice(0, 30);
                
        //         console.log("data other", flatOtherData)
                
        //         console.log("filtered data", filteredData)
        //         updateDisplay(filteredData);
         
           
        //         callOpenAI(textareaValue, finalTexts).then(openAIOutput => {
        //             // Data to send
        //             incrementProgress(20);
        //             const postData = {
        //                 input: textareaValue,
        //                 output: openAIOutput,
        //             };
                
        //             // Fetch API to POST data
        //             fetch('https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/inquirePrompts', {
        //                 method: 'POST',
        //                 headers: {
        //                     "Authorization": `Bearer ${token}`,
        //                     'Content-Type': 'application/json',
        //                 },
        //                 body: JSON.stringify(postData),
        //             })
        //             .then(response => response.json())
        //             .then(data => {
        //                 // console.log('Prompt saved successfully:', data);
        //             })
        //             .catch((error) => {
        //                 console.error('Error posting inquire prompt:', error);
        //             });
    
        //         });
                
        //         // setJsonData([]);
        //         finalTexts = "";
        //         // setJsonData([]);
        //         setTimeout(() => setIsRequesting(false), 15000);

            //     // setSupportEvidence(supportData);
            //     // setReferenceSources(referenceData);
            //     // setRelevanceDisplay(relevanceDisplay);

            //     // setAnswerText('output here'); // Update the answer text
            // })
            // .catch(error => {
            //     console.error('There has been a problem with your fetch operation:', error);
            //     clearInterval(statusInterval);
            //     clearInterval(progressInterval);
            //     alert('An error occurred. Please refresh your browser tab.');
            //     setIsRequesting(false);
            //     setAnswerText("Failed to load data, please try again.");
            // });
    };
  
    // const handleKeyFindings = () => {
    //     setIsRequesting(true);
        
    //     const statusInterval = updateStatusText();
    //     setProgress(10);

    //     let progressInterval;
    //       // Simulate progress for the loading bar
    //     const incrementProgress = (increment) => {
    //         setProgress(prevProgress => {
    //             const nextProgress = prevProgress + increment;
    //             if (nextProgress >= 100) {
    //                 clearInterval(progressInterval); // Ensure clearing interval
    //                 return 100;
    //             }
    //             return nextProgress;
    //         });
    //     };
    //     // setJsonData([]);
    //     let finalTexts = "";
    //     let texts = "";
    //     // Get the value from the textarea
    //     var textareaValue = document.querySelector('.inquire-textbox').value;

    //     // Encode the textarea content to be used in a URL
    //     var encodedValue = encodeURIComponent(textareaValue);

    //     // Construct the URL with the textarea content
    //     var url1 = `https://archive.tylo.ai/query_arxiv/arxiv?question=${encodedValue}`;
    //     var url2 = `https://archive.tylo.ai/query_pubmed/pubmed?question=${encodedValue}`;
    //     var url3 = `https://archive.tylo.ai/google_query/google?question=${encodedValue}`;

    //     // Send the request to the server
    //     Promise.all([fetch(url1), fetch(url2), fetch(url3)])
    //         .then(responses => Promise.all(responses.map(res => {
    //             incrementProgress(30);
    //             if (res.ok) return res.json();
    //             throw new Error('Network response was not ok.');
    //         })))
    //         .then(dataArray => {
    //             incrementProgress(30);
    //             clearInterval(statusInterval); // Stop updating status text
    //             setAnswerText("Compiling the most relevant information...");

    //             const data = dataArray.flat(); // Combine arrays if both URLs return arrays

    //             console.log(data);

    //             // const minRelevance = Math.min(...data.map(item => item.relevance));
    //             // const maxRelevance = Math.max(...data.map(item => item.relevance));


               
               
    //             // setJsonData(data);
    //             setItemCount(data)
                

    //             data.sort((a, b) => b.relevance - a.relevance);
    //             // Limit to the most relevant 30 items
    //             const top30Items = data.slice(0, 30);

    //             texts = top30Items.map((item, idx) => `${item.paragraph}`);
    //             finalTexts = texts.join("\n");
    //             // console.log("context", finalTexts )


    //             const sortedData = data.sort((a, b) => b.relevance - a.relevance);
    //             const filteredData = sortedData.slice(0, data.length);
                 
    //             updateDisplay(filteredData);
         
           
    //             callOpenAI_K(textareaValue, finalTexts).then(openAIOutput => {
    //                 // Data to send
    //                 incrementProgress(30);
    //                 const postData = {
    //                     input: textareaValue,
    //                     output: openAIOutput,
    //                 };
                
    //                 // Fetch API to POST data
    //                 fetch('https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/inquirePrompts', {
    //                     method: 'POST',
    //                     headers: {
    //                         "Authorization": `Bearer ${token}`,
    //                         'Content-Type': 'application/json',
    //                     },
    //                     body: JSON.stringify(postData),
    //                 })
    //                 .then(response => response.json())
    //                 .then(data => {
    //                     // console.log('Prompt saved successfully:', data);
    //                 })
    //                 .catch((error) => {
    //                     console.error('Error posting inquire prompt:', error);
    //                 });
    
    //             });
                
    //             // setJsonData([]);
    //             finalTexts = "";
    //             // setJsonData([]);
    //             setTimeout(() => setIsRequesting(false), 15000);

    //             // setSupportEvidence(supportData);
    //             // setReferenceSources(referenceData);
    //             // setRelevanceDisplay(relevanceDisplay);

    //             // setAnswerText('output here'); // Update the answer text
    //         })
    //         .catch(error => {
    //             console.error('There has been a problem with your fetch operation:', error);
    //             clearInterval(statusInterval);
    //             clearInterval(progressInterval);
    //             alert('An error occurred. Please refresh your browser tab.');
    //             setIsRequesting(false);
    //             setAnswerText("Failed to load data, please try again.");
    //         });
    // };
    
    // const handleSWOT = () => {
    //     setIsRequesting(true);
        
    //     const statusInterval = updateStatusText();
    //     setProgress(10);

    //     let progressInterval;
    //       // Simulate progress for the loading bar
    //     const incrementProgress = (increment) => {
    //         setProgress(prevProgress => {
    //             const nextProgress = prevProgress + increment;
    //             if (nextProgress >= 100) {
    //                 clearInterval(progressInterval); // Ensure clearing interval
    //                 return 100;
    //             }
    //             return nextProgress;
    //         });
    //     };
    //     // setJsonData([]);
    //     let finalTexts = "";
    //     let texts = "";
    //     // Get the value from the textarea
    //     var textareaValue = document.querySelector('.inquire-textbox').value;

    //     // Encode the textarea content to be used in a URL
    //     var encodedValue = encodeURIComponent(textareaValue);

    //     // Construct the URL with the textarea content
    //     var url1 = `https://archive.tylo.ai/query_arxiv/arxiv?question=${encodedValue}`;
    //     var url2 = `https://archive.tylo.ai/query_pubmed/pubmed?question=${encodedValue}`;
    //     var url3 = `https://archive.tylo.ai/google_query/google?question=${encodedValue}`;

    //     // Send the request to the server
    //     Promise.all([fetch(url1), fetch(url2), fetch(url3)])
    //         .then(responses => Promise.all(responses.map(res => {
    //             incrementProgress(30);
    //             if (res.ok) return res.json();
    //             throw new Error('Network response was not ok.');
    //         })))
    //         .then(dataArray => {
    //             incrementProgress(30);
    //             clearInterval(statusInterval); // Stop updating status text
    //             setAnswerText("Compiling the most relevant information...");

    //             const data = dataArray.flat(); // Combine arrays if both URLs return arrays

    //             console.log(data);

    //             // const minRelevance = Math.min(...data.map(item => item.relevance));
    //             // const maxRelevance = Math.max(...data.map(item => item.relevance));


               
               
    //             // setJsonData(data);
    //             setItemCount(data)
                

    //             data.sort((a, b) => b.relevance - a.relevance);
    //             // Limit to the most relevant 30 items
    //             const top30Items = data.slice(0, 30);

    //             texts = top30Items.map((item, idx) => `${item.paragraph}`);
    //             finalTexts = texts.join("\n");
    //             // console.log("context", finalTexts )


    //             const sortedData = data.sort((a, b) => b.relevance - a.relevance);
    //             const filteredData = sortedData.slice(0, data.length);
                 
    //             updateDisplay(filteredData);
         
           
    //             callOpenAI_S(textareaValue, finalTexts).then(openAIOutput => {
    //                 // Data to send
    //                 incrementProgress(30);
    //                 const postData = {
    //                     input: textareaValue,
    //                     output: openAIOutput,
    //                 };
                
    //                 // Fetch API to POST data
    //                 fetch('https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/inquirePrompts', {
    //                     method: 'POST',
    //                     headers: {
    //                         "Authorization": `Bearer ${token}`,
    //                         'Content-Type': 'application/json',
    //                     },
    //                     body: JSON.stringify(postData),
    //                 })
    //                 .then(response => response.json())
    //                 .then(data => {
    //                     // console.log('Prompt saved successfully:', data);
    //                 })
    //                 .catch((error) => {
    //                     console.error('Error posting inquire prompt:', error);
    //                 });
    
    //             });
                
    //             // setJsonData([]);
    //             finalTexts = "";
    //             // setJsonData([]);
    //             setTimeout(() => setIsRequesting(false), 15000);

    //             // setSupportEvidence(supportData);
    //             // setReferenceSources(referenceData);
    //             // setRelevanceDisplay(relevanceDisplay);

    //             // setAnswerText('output here'); // Update the answer text
    //         })
    //         .catch(error => {
    //             console.error('There has been a problem with your fetch operation:', error);
    //             clearInterval(statusInterval);
    //             clearInterval(progressInterval);
    //             alert('An error occurred. Please refresh your browser tab.');
    //             setIsRequesting(false);
    //             setAnswerText("Failed to load data, please try again.");
    //         });
    // };

    // const handleEvidencedBasedOpinion = () => {
    //     setIsRequesting(true);
        
    //     const statusInterval = updateStatusText();
    //     setProgress(10);

    //     let progressInterval;
    //       // Simulate progress for the loading bar
    //     const incrementProgress = (increment) => {
    //         setProgress(prevProgress => {
    //             const nextProgress = prevProgress + increment;
    //             if (nextProgress >= 100) {
    //                 clearInterval(progressInterval); // Ensure clearing interval
    //                 return 100;
    //             }
    //             return nextProgress;
    //         });
    //     };
    //     // setJsonData([]);
    //     let finalTexts = "";
    //     let texts = "";
    //     // Get the value from the textarea
    //     var textareaValue = document.querySelector('.inquire-textbox').value;

    //     // Encode the textarea content to be used in a URL
    //     var encodedValue = encodeURIComponent(textareaValue);

    //     // Construct the URL with the textarea content
    //     var url1 = `https://archive.tylo.ai/query_arxiv/arxiv?question=${encodedValue}`;
    //     var url2 = `https://archive.tylo.ai/query_pubmed/pubmed?question=${encodedValue}`;
    //     var url3 = `https://archive.tylo.ai/google_query/google?question=${encodedValue}`;

    //     // Send the request to the server
    //     Promise.all([fetch(url1), fetch(url2), fetch(url3)])
    //         .then(responses => Promise.all(responses.map(res => {
    //             incrementProgress(30);
    //             if (res.ok) return res.json();
    //             throw new Error('Network response was not ok.');
    //         })))
    //         .then(dataArray => {
    //             incrementProgress(30);
    //             clearInterval(statusInterval); // Stop updating status text
    //             setAnswerText("Compiling the most relevant information...");

    //             const data = dataArray.flat(); // Combine arrays if both URLs return arrays

    //             console.log(data);

    //             // const minRelevance = Math.min(...data.map(item => item.relevance));
    //             // const maxRelevance = Math.max(...data.map(item => item.relevance));


               
               
    //             // setJsonData(data);
    //             setItemCount(data)
                

    //             data.sort((a, b) => b.relevance - a.relevance);
    //             // Limit to the most relevant 30 items
    //             const top30Items = data.slice(0, 30);

    //             texts = top30Items.map((item, idx) => `${item.paragraph}`);
    //             finalTexts = texts.join("\n");
    //             // console.log("context", finalTexts )


    //             const sortedData = data.sort((a, b) => b.relevance - a.relevance);
    //             const filteredData = sortedData.slice(0, data.length);
                 
    //             updateDisplay(filteredData);
         
           
    //             callOpenAI_O(textareaValue, finalTexts).then(openAIOutput => {
    //                 // Data to send
    //                 incrementProgress(30);
    //                 const postData = {
    //                     input: textareaValue,
    //                     output: openAIOutput,
    //                 };
                
    //                 // Fetch API to POST data
    //                 fetch('https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/inquirePrompts', {
    //                     method: 'POST',
    //                     headers: {
    //                         "Authorization": `Bearer ${token}`,
    //                         'Content-Type': 'application/json',
    //                     },
    //                     body: JSON.stringify(postData),
    //                 })
    //                 .then(response => response.json())
    //                 .then(data => {
    //                     // console.log('Prompt saved successfully:', data);
    //                 })
    //                 .catch((error) => {
    //                     console.error('Error posting inquire prompt:', error);
    //                 });
    
    //             });
                
    //             // setJsonData([]);
    //             finalTexts = "";
    //             // setJsonData([]);
    //             setTimeout(() => setIsRequesting(false), 15000);

    //             // setSupportEvidence(supportData);
    //             // setReferenceSources(referenceData);
    //             // setRelevanceDisplay(relevanceDisplay);

    //             // setAnswerText('output here'); // Update the answer text
    //         })
    //         .catch(error => {
    //             console.error('There has been a problem with your fetch operation:', error);
    //             clearInterval(statusInterval);
    //             clearInterval(progressInterval);
    //             alert('An error occurred. Please refresh your browser tab.');
    //             setIsRequesting(false);
    //             setAnswerText("Failed to load data, please try again.");
    //         });
    // };


    // const handlePatentWork = () => {
    //     setIsRequesting(true);
        
    //     const statusInterval = updateStatusText();
    //     setProgress(10);

    //     let progressInterval;
    //       // Simulate progress for the loading bar
    //     const incrementProgress = (increment) => {
    //         setProgress(prevProgress => {
    //             const nextProgress = prevProgress + increment;
    //             if (nextProgress >= 100) {
    //                 clearInterval(progressInterval); // Ensure clearing interval
    //                 return 100;
    //             }
    //             return nextProgress;
    //         });
    //     };
    //     // setJsonData([]);
    //     let finalTexts = "";
    //     let texts = "";
    //     // Get the value from the textarea
    //     var textareaValue = document.querySelector('.inquire-textbox').value;

    //     // Encode the textarea content to be used in a URL
    //     var encodedValue = encodeURIComponent(textareaValue);

    //     // Construct the URL with the textarea content
    //     var url1 = `https://archive.tylo.ai/google_patent/google?question=${encodedValue}`;
    //     var url2 = `https://archive.tylo.ai/lens_patent/lens?question=${encodedValue}`;

    //     // Send the request to the server
    //     Promise.all([fetch(url1), fetch(url2)])
    //         .then(responses => Promise.all(responses.map(res => {
    //             incrementProgress(30);
    //             if (res.ok) return res.json();
    //             throw new Error('Network response was not ok.');
    //         })))
    //         .then(dataArray => {
    //             incrementProgress(30);
    //             clearInterval(statusInterval); // Stop updating status text
    //             setAnswerText("Compiling the most relevant information...");

    //             const data = dataArray.flat(); // Combine arrays if both URLs return arrays

    //             console.log(data);

    //             // const minRelevance = Math.min(...data.map(item => item.relevance));
    //             // const maxRelevance = Math.max(...data.map(item => item.relevance));


               
               
    //             // setJsonData(data);
    //             setItemCount(data)
                

    //             data.sort((a, b) => b.relevance - a.relevance);
    //             // Limit to the most relevant 30 items
    //             const top30Items = data.slice(0, 30);

    //             texts = top30Items.map((item, idx) => `${item.paragraph}`);
    //             finalTexts = texts.join("\n");
    //             // console.log("context", finalTexts )


    //             const sortedData = data.sort((a, b) => b.relevance - a.relevance);
    //             const filteredData = sortedData.slice(0, data.length);
                 
    //             updateDisplay(filteredData);
         
           
    //             callOpenAI_Patent(textareaValue, finalTexts).then(openAIOutput => {
    //                 // Data to send
    //                 incrementProgress(30);
    //                 const postData = {
    //                     input: textareaValue,
    //                     output: openAIOutput,
    //                 };
                
    //                 // Fetch API to POST data
    //                 fetch('https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/inquirePrompts', {
    //                     method: 'POST',
    //                     headers: {
    //                         "Authorization": `Bearer ${token}`,
    //                         'Content-Type': 'application/json',
    //                     },
    //                     body: JSON.stringify(postData),
    //                 })
    //                 .then(response => response.json())
    //                 .then(data => {
    //                     // console.log('Prompt saved successfully:', data);
    //                 })
    //                 .catch((error) => {
    //                     console.error('Error posting inquire prompt:', error);
    //                 });
    
    //             });
                
    //             // setJsonData([]);
    //             finalTexts = "";
    //             // setJsonData([]);
    //             setTimeout(() => setIsRequesting(false), 15000);

    //             // setSupportEvidence(supportData);
    //             // setReferenceSources(referenceData);
    //             // setRelevanceDisplay(relevanceDisplay);

    //             // setAnswerText('output here'); // Update the answer text
    //         })
    //         .catch(error => {
    //             console.error('There has been a problem with your fetch operation:', error);
    //             clearInterval(statusInterval);
    //             clearInterval(progressInterval);
    //             alert('An error occurred. Please refresh your browser tab.');
    //             setIsRequesting(false);
    //             setAnswerText("Failed to load data, please try again.");
    //         });
    // }; 



    // const combined = process.env.REACT_APP_OPEN_AI_KEY

  

   
    // /******************************************** Open AI calls *******************************************************************/
    // const inputPrompt = `You are tasked with providing a detailed answer or summary to a specific question or input using only the evidence provided. Your response should be structured and professional, resembling the work of a consultant. Below are the steps you should follow to accomplish this task effectively:

    // 1. Read the QUESTION carefully. Understand what is being asked, but do not reiterate the question in your answer. Keep your response natural and direct.
    
    // 2. Examine the EVIDENCE provided. This is the only information you can use to construct your answer. Do not add any details from outside sources.
    
    // 3. Begin formulating your response by considering the following structure:
    //    - Introduction: Briefly introduce the topic or issue at hand without restating the QUESTION.
    //    - Analysis: Dive into the EVIDENCE, quoting relevant dates, percentages, or formulas to support your analysis. For example, if the EVIDENCE mentions "the company's revenue grew by 20% in 2020," directly quote this in your analysis.
    //    - Opinion: Based on the EVIDENCE, share your professional opinion on the matter. Ensure your opinion is supported by the data or details found in the EVIDENCE.
    //    - Conclusion: Draw a conclusion that wraps up your analysis and opinion, providing a clear answer or summary to the QUESTION.
    
    // 4. Structure your answer with bullet points or numbered lists to make it easy to follow. Your response should be long and detailed, reflecting a thorough examination of the EVIDENCE.
    
    // 5. Remember, your goal is to present the information like a professional consultant. This means your answer should be well-organized, insightful, and supported by the EVIDENCE.

    // 6. Make the answer long.
    
    
    // Remember, do not add any information not found in the EVIDENCE, and maintain a professional tone throughout your response, and follow all the steps provided above consistently.`;
    
    
    // /* systemPrompt = `Answer the following input/question with either a detailed direct answer to the question/input or a detailed summary using the context below. Use the following text without adding any additional details.`;
    // `Provide an answer to the question, like you are an expert. If specific details are not available, infer general conclusions based on the context without explicitly mentioning it in your response. Include numerical data and specific figures when relevant and available. Do not add information beyond what is given, and avoid referencing the context directly in your answer.`
    // systemPrompt = `Answer the following input/question with either a detailed direct answer to the question/input or a detailed summary using the Evidence below, do not reiterate the question in the answer keep it natural Add you opinion on the questions supported by the Evidence and draw conclusions. Use the following Evidence without adding any additional details, if you see any dates, percentages or formula, quote them in the response and present it like a professional, make it structured with bullet points or numbered list and long.`;*/
    
  
    
    // const callOpenAI = async (question, finalTexts) => {
    //     setIsRequesting(true);

    //     setAnswerText("Processing your request..."); // Clears the answer text box content
        
    //     // const systemPrompt = inputPrompt;
    //     // const userPrompt = `Question/Input: ${question}\nEvidence: ${finalTexts} \nPatent: ${patents}`;
    
    //     // const prompt = [
    //     //     { "role": "system", "content": systemPrompt },
    //     //     { "role": "user", "content": userPrompt }
    //     // ];

    //     const requestData = {
    //         question: question,
    //         finalTexts: finalTexts,
    //         systemPrompt: inputPrompt,  
    //     };
    
    //     try {
    //         const response = await fetch("https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/openai/inquiry", {
    //             method: "POST",
    //             headers: {
    //                 "Authorization": `Bearer ${token}`,
    //                 "Content-Type": "application/json",
    //                 // "Authorization": `Bearer ${combined}` 
    //                 // Replace with your actual API key
    //             },
    //             body: JSON.stringify(requestData)
    //         });

    //         if (!response.ok) {
    //             throw new Error(`HTTP error! Status: ${response.status}`);
    //         }

    //         const data = await response.json();
    //         clearInterval(progressInterval);
    //         setAnswerText(data.message); // Update the answer state
    //         console.log(data.message)

    //         deductCredits(1);
    //         return data.message;
            
    //     } catch (error) {
    //         console.error("Error calling OpenAI API:", error);
    //         // Handling different types of errors
    //         if (error.name === "TypeError" && error.message === "Failed to fetch") {
    //             // This could be due to network issues, CORS, etc.
    //             setAnswerText("Failed to reach AI servers. Please check your internet connection or try again later.");
    //         } else if (error.name === "Error" && error.message.startsWith("HTTP error! Status:")) {
    //             // This could be due to the OpenAI API being down, or an invalid API key
    //             setAnswerText("Unable to process your request at the moment due to API usage. Please try again later.");
    //         } else {
    //             // General error message for other types of errors
    //             setAnswerText("An unexpected error occurred when summarizing the evidence. Please try again or leave an message at the bottom.");
    //         }
    //     } finally {
    //         setIsRequesting(false); // This should be in finally so it runs whether or not there was an error
    //         clearInterval(progressInterval); // Assuming progressInterval is a function that updates a loading state
    //     }

    // }

  
    /*************************************************************************************************** */

    
    /************************************ Supabase call ************************************/

    // const QuestionPrompt = `From the question asked, generate 4 options on consultantacy report types that 
    // could be related to the question asked and pose another question in the style, 'What type of report is this' followed by the 4 options`

    // const inital_question = "key research findings in digital payment technology";
    // let reportType = '';
    // let exec;
    // let key;
    // let swot;
    // let opinion;

    // const callOpenAI_Q = async (inital_question) => {
    //     // setIsRequesting(true);

    //     // setAnswerText("Processing your request..."); // Clears the answer text box content
        
    //     const systemPrompt = QuestionPrompt;
    //     const userPrompt = `Question/Input: ${inital_question}`;
    
    //     const prompt = [
    //         { "role": "system", "content": systemPrompt },
    //         { "role": "user", "content": userPrompt }
    //     ];
    
    //     try {
    //         const response = await fetch("https://api.openai.com/v1/chat/completions", {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Authorization": `Bearer ${combined}` 
    //                 // Replace with your actual API key
    //             },
    //             body: JSON.stringify({
    //                 model: "gpt-3.5-turbo-16k",
    //                 messages: prompt,
    //                 temperature: 0.7,
    //                 max_tokens: 4096,
    //                 top_p: 1,
    //                 frequency_penalty: 0,
    //                 presence_penalty: 0
    //             })
    //         });

    //         if (!response.ok) {
    //             throw new Error(`HTTP error! Status: ${response.status}`);
    //         }

    //         const data = await response.json();
    //         // clearInterval(progressInterval);
    //         setAnswerText(data.choices[0].message.content); // Update the answer state
    //         // reportType = data.choices[0].message.content;
    //         console.log(data.choices[0].message.content)

    //         deductCredits(1);
            
    //     } catch (error) {
    //         console.error("Error calling OpenAI API:", error);
    //         // Handling different types of errors
    //         if (error.name === "TypeError" && error.message === "Failed to fetch") {
    //             // This could be due to network issues, CORS, etc.
    //             // setAnswerText("Failed to reach AI servers. Please check your internet connection or try again later.");
    //         } else if (error.name === "Error" && error.message.startsWith("HTTP error! Status:")) {
    //             // This could be due to the OpenAI API being down, or an invalid API key
    //             // setAnswerText("Unable to process your request at the moment due to API usage. Please try again later.");
    //         } else {
    //             // General error message for other types of errors
    //             // setAnswerText("An unexpected error occurred when summarizing the evidence. Please try again or leave an message at the bottom.");
    //         }
    //     } finally {
    //         // setIsRequesting(false); // This should be in finally so it runs whether or not there was an error
    //         // clearInterval(progressInterval); // Assuming progressInterval is a function that updates a loading state
    //     }

    // }


    // const QuestionPrompt_key = `User has set specific intructions on how they want evidence data reported on the Key research findings.
    
    // In addition to these instructions, I also want you to add the following structure:
    // 1) Should be 1000 words.
    // 2) Introduction (very short).
    // 3) Quote the evidence number when answering.
    // 4) Key results analysis.
    // 5) Key emerging technologies and methodologies used.
    // 6) Key open discussions
    // 7) Conclusion
    // `

    // const QuestionPrompt_swot = `User has set specific intructions on how they want evidence data reported on the  SWOT analysis.
    
    // In addition to these instructions, I also want you to add the following structure:
    // 1) Should be 1000 words.
    // 2) Introduction (very short).
    // 3) Quote the evidence number when answering the SWOT
    // 4) Strengths.
    // 5) Weakness.
    // 6) Opportunities.
    // 7) Threats
    // 8) Conclusion
    // `

    // const QuestionPrompt_opinion = `User has set specific intructions on how they want evidence data reported on your opinion as a consultant.
    
    // In addition to these instructions, I also want you to add the following structure:
    // 1) Should be 1000 words.
    // 2) Dont add a Title
    // 3) Introduction (very short).
    // 4) Positive opinions.
    // 5) Negative opinions.
    // 6) Conclusion
    // `

    // const callOpenAI_E = async (question, finalTexts) => {
    //     // setIsRequesting(true);

    //     // setAnswerText("Processing your request..."); // Clears the answer text box content
        
    //     const systemPrompt = QuestionPrompt_exec;
    //     const userPrompt = `Question/Input: ${question}\nEvidence: ${finalTexts}`;
    
    //     const prompt = [
    //         { "role": "system", "content": systemPrompt },
    //         { "role": "user", "content": userPrompt }
    //     ];
    
    //     try {
    //         const response = await fetch("https://api.openai.com/v1/chat/completions", {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Authorization": `Bearer ${combined}` 
    //                 // Replace with your actual API key
    //             },
    //             body: JSON.stringify({
    //                 model: "gpt-3.5-turbo-16k",
    //                 messages: prompt,
    //                 temperature: 0.7,
    //                 max_tokens: 4096,
    //                 top_p: 1,
    //                 frequency_penalty: 0,
    //                 presence_penalty: 0
    //             })
    //         });

    //         if (!response.ok) {
    //             throw new Error(`HTTP error! Status: ${response.status}`);
    //         }

    //         const data = await response.json();
    //         // clearInterval(progressInterval);
    //         // setAnswerText(data.choices[0].message.content); // Update the answer state
    //         exec = data.choices[0].message.content;
    //         console.log(data.choices[0].message.content)

    //         deductCredits(1);
            
    //     } catch (error) {
    //         console.error("Error calling OpenAI API:", error);
    //     } 

    // }

    // const callOpenAI_K = async (question, finalTexts) => {
    //     // setIsRequesting(true);

    //     // setAnswerText("Processing your request..."); // Clears the answer text box content
        
    //     // const systemPrompt = QuestionPrompt_key;
    //     // const userPrompt = `Question/Input: ${question}\nEvidence: ${finalTexts}`;
    
    //     // const prompt = [
    //     //     { "role": "system", "content": systemPrompt },
    //     //     { "role": "user", "content": userPrompt }
    //     // ];

    //     const requestData = {
    //         question: question,
    //         finalTexts: finalTexts,
    //         systemPrompt: QuestionPrompt_key,  
    //     };
    
    //     try {
    //         const response = await fetch("https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/openai/inquiry", {
    //             method: "POST",
    //             headers: {
    //                 "Authorization": `Bearer ${token}`,
    //                 "Content-Type": "application/json",
    //                 // "Authorization": `Bearer ${combined}` 
    //                 // Replace with your actual API key
    //             },
    //             body: JSON.stringify(requestData)
    //         });

    //         if (!response.ok) {
    //             throw new Error(`HTTP error! Status: ${response.status}`);
    //         }

    //         const data = await response.json();
    //         // clearInterval(progressInterval);
    //         setAnswerText(data.message); // Update the answer state
    //         // key = data.choices[0].message.content;
    //         console.log(data.message)

    //         deductCredits(1);
    //         return data.message;
            
    //     } catch (error) {
    //         console.error("Error calling OpenAI API:", error);
    //     } 

    // }

    // const callOpenAI_S = async (question, finalTexts) => {
    //     // setIsRequesting(true);

    //     // setAnswerText("Processing your request..."); // Clears the answer text box content
        
    //     // const systemPrompt = QuestionPrompt_swot;
    //     // const userPrompt = `Question/Input: ${question}\nEvidence: ${finalTexts}`;
    
    //     // const prompt = [
    //     //     { "role": "system", "content": systemPrompt },
    //     //     { "role": "user", "content": userPrompt }
    //     // ];

    //     const requestData = {
    //         question: question,
    //         finalTexts: finalTexts,
    //         systemPrompt: QuestionPrompt_swot,  
    //     };
    
    //     try {
    //         const response = await fetch("https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/openai/inquiry", {
    //             method: "POST",
    //             headers: {
    //                 "Authorization": `Bearer ${token}`,
    //                 "Content-Type": "application/json",
    //                 // "Authorization": `Bearer ${combined}` 
    //                 // Replace with your actual API key
    //             },
    //             body: JSON.stringify(requestData)
    //         });

    //         if (!response.ok) {
    //             throw new Error(`HTTP error! Status: ${response.status}`);
    //         }

    //         const data = await response.json();
    //         // clearInterval(progressInterval);
    //         setAnswerText(data.message); // Update the answer state
    //         // swot = data.choices[0].message.content;
    //         console.log(data.message)

    //         deductCredits(1);
    //         return data.message;

    //     } catch (error) {
    //         console.error("Error calling OpenAI API:", error);
    //     } 

    // }


    // const callOpenAI_O = async (question, finalTexts) => {
    //     // setIsRequesting(true);

    //     // setAnswerText("Processing your request..."); // Clears the answer text box content
        
    //     // const systemPrompt = QuestionPrompt_opinion;
    //     // const userPrompt = `Question/Input: ${question}\nEvidence: ${finalTexts}`;
    
    //     // const prompt = [
    //     //     { "role": "system", "content": systemPrompt },
    //     //     { "role": "user", "content": userPrompt }
    //     // ];

    //     const requestData = {
    //         question: question,
    //         finalTexts: finalTexts,
    //         systemPrompt: QuestionPrompt_opinion,  
    //     };
    
    //     try {
    //         const response = await fetch("https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/openai/inquiry", {
    //             method: "POST",
    //             headers: {
    //                 "Authorization": `Bearer ${token}`,
    //                 "Content-Type": "application/json",
    //                 // "Authorization": `Bearer ${combined}` 
    //                 // Replace with your actual API key
    //             },
    //             body: JSON.stringify(requestData)
    //         });

    //         if (!response.ok) {
    //             throw new Error(`HTTP error! Status: ${response.status}`);
    //         }

    //         const data = await response.json();
    //         // clearInterval(progressInterval);
    //         setAnswerText(data.message); // Update the answer state
    //         // opinion = data.choices[0].message.content;
    //         console.log(data.message)

    //         deductCredits(1);
    //         return data.message;

    //     } catch (error) {
    //         console.error("Error calling OpenAI API:", error);
    //     } 
    // }
    
    // const patentPrompt = `You are tasked with analyzing several pieces of patent information to perform Right of Use (RoU) work. This involves determining whether a product or process infringes on any patents within the provided data and if there are any freedoms to operate (FTO) without legal issues. Here's how you'll accomplish this task:

    // 1. Start by reviewing the <patent_data> {$PATENT_DATA} </patent_data> provided. This data includes details about various patents, such as patent numbers, titles, inventors, assignees, filing and grant dates, and claims.
    
    // 2. Analyze each patent claim to understand the scope of what is being protected. Pay close attention to the claims section, as it defines the boundaries of the patent's exclusivity.
    
    // 3. For each patent, consider the following:
    //    - The technological field and applicability of the patent.
    //    - The geographical regions where the patent is valid.
    //    - The expiration date of the patent to ensure it is still active.
    //    - Quote the PATENT_DATA number when answering
    
    // 4. Determine if the product or process in question falls within the scope of any of these patents. Consider all aspects of the product/process, including its design, functionality, and the methods used to achieve these functionalities.
    
    // 5. If a potential infringement is identified, explore any available licenses, prior art, or other legal exceptions that might permit use.
    
    // 6. Summarize your findings for each patent, including:
    //    - Whether there is a risk of infringement.
    //    - Recommendations for avoiding infringement, if any risks are identified.
    //    - Any opportunities for FTO that you have identified.

    
    // Write your summary and recommendations inside <answer> tags. If you need to, use <scratchpad> tags to organize your thoughts and plan your analysis before finalizing your response. Remember, your goal is to provide a clear and concise evaluation of the right of use based on the patent data provided.`

  

    // const callOpenAI_Patent = async (question, PATENT_DATA) => {
    //     setIsRequesting(true);

    //     setAnswerText("Processing your request..."); // Clears the answer text box content
        
    //     // const systemPrompt = patentPrompt;
    //     // const userPrompt = `Question/Input: ${question}\npatent_data: ${PATENT_DATA}`;
    
    //     // const prompt = [
    //     //     { "role": "system", "content": systemPrompt },
    //     //     { "role": "user", "content": userPrompt }
    //     // ];

    //     const requestData = {
    //         question: question,
    //         finalTexts: PATENT_DATA,
    //         systemPrompt: patentPrompt,  
    //     };
    
    //     try {
    //         const response = await fetch("https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/openai/inquiry", {
    //             method: "POST",
    //             headers: {
    //                 "Authorization": `Bearer ${token}`,
    //                 "Content-Type": "application/json",
    //                 // "Authorization": `Bearer ${combined}` 
    //                 // Replace with your actual API key
    //             },
    //             body: JSON.stringify(requestData)
    //         });

    //         if (!response.ok) {
    //             throw new Error(`HTTP error! Status: ${response.status}`);
    //         }

    //         const data = await response.json();
    //         clearInterval(progressInterval);
    //         setAnswerText(data.message); // Update the answer state
    //         console.log(data.message)

    //         deductCredits(1);
    //         return data.message;
            
    //     } catch (error) {
    //         console.error("Error calling OpenAI API:", error);
    //         // Handling different types of errors
    //         if (error.name === "TypeError" && error.message === "Failed to fetch") {
    //             // This could be due to network issues, CORS, etc.
    //             setAnswerText("Failed to reach AI servers. Please check your internet connection or try again later.");
    //         } else if (error.name === "Error" && error.message.startsWith("HTTP error! Status:")) {
    //             // This could be due to the OpenAI API being down, or an invalid API key
    //             setAnswerText("Unable to process your request at the moment due to API usage. Please try again later.");
    //         } else {
    //             // General error message for other types of errors
    //             setAnswerText("An unexpected error occurred when summarizing the evidence. Please try again or leave an message at the bottom.");
    //         }
    //     } finally {
    //         setIsRequesting(false); // This should be in finally so it runs whether or not there was an error
    //         clearInterval(progressInterval); // Assuming progressInterval is a function that updates a loading state
    //     }

    // }

    /********************************************************************************************************* */
   

    const customStyles = {
        h1: {
          fontSize: '32px',    // Customize the size of <h1>
          margin: '0px 0',    // Adjust spacing
          marginBottom: '0px',
        },
        h2: {
          fontSize: '26px',    // Customize the size of <h2>
          margin: '0px 0',    // Adjust spacing
          marginBottom: '0px',
        },
        h3: {
            // fontSize: '26px',    // Customize the size of <h2>
            margin: '0px 0',    // Adjust spacing
          },
        p: {
          lineHeight: '1.3',   // Adjust line spacing for paragraphs
          margin: '0px 0',    // Customize margin between paragraphs
          marginBottom: '0px',
        },
        br: {
            lineHeight: '0.5',   // Reduce the space after <br> tags
            display: 'block',    // Ensure <br> behaves correctly as a block element
            marginBottom: '0px', // Adjust vertical space after <br>
          },
        ul: {
            margin: '10px 0',     // Adjust the margin above and below the list
            // paddingLeft: '20px',  // Indentation for the list
            // listStyleType: 'disc', // Bullet point style (you can change this to 'circle', 'square', etc.)
        },
        li: {
            marginBottom: '2px',  // Adjust the space between list items
            lineHeight: '1.4',    // Line height for list items
         },
      };


    console.log('Inquire component about to render JSX');
    return (
        <div className="feature-page">
            
                <div className="feature-page-icon">
                    <img src={Tylo_icon} alt="Tylo Icon" className="tylo-icon" />
                    <div className="tabs">
                        <div className="top-bar-tab-current_page">
                            <a href="/#features" className="top-bar-text">Features</a> {/* Link to the Features page */}
                        </div>
                        <div className="top-bar-tab">
                            <a href="/#about" className="top-bar-text">About Us</a> {/* Link to the About page */}
                        </div>
                    </div>
                    <img src={googleProfileImageUrl} alt="Profile Icon" onClick={navigateToSettings} className="profile-icon" />
                    <div className="login-button" onClick={handleSignOutClick}>
                        <span className="login-text" >Sign Out</span>
                    </div>
                </div>

          <div className="gradient-rectangle">
            <h2 className="feature-title">Deeptech innovation Assistant</h2>
            
            <div className="tab-horizontal-container">
                <div className="sign-in" >
                    <span className="sign-in-text" >Inquire</span>
                </div>
                <div className="sign-in-disabled" onClick={navigateToFeatures}>
                    <div  className="sign-in-text-disabled" >Track</div>
                </div>
            </div>
          </div>
          <div className="feature-page-2">
            <div className="Inquire_feature-frame-108">
                <h2 className="inquire-header">Inquire Anything</h2>
                <div className="inquire-frame-95">
                    <div className="helper-container">
                        <h2 className="inquire-text-1">
                            The starting point of your evidence-based innovation.
                        </h2>
                        <div className="instructions-container">
                            <div className="instructions">
                                Instructions 
                            </div>
                            <div className="instructions-toggle dropdown-button" onClick={(e) => { e.stopPropagation(); setShowInstructions(!showInstructions); }}>
                                    {showInstructions ?  '▲' : '▼'}
                            </div>
                        </div>
                    </div>
                    {showInstructions && (
                    <div className="instruction-card">
                        <div className="inst-top-col">
                            <div className="inst-item">
                                <h1 className="inst-card-H">What is it for?</h1>
                                <div className="inst-card-p">
                                    <ul className="inst-card-ul">
                                        <li>Get an answer to deeptech questions</li>
                                        <li>Learn innovative technology topics or concepts</li>
                                        <li>Find evidence for hypothesis & assumptions</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="inst-item">
                                <h1 className="inst-card-H">What is it not a good fit for?</h1>
                                <div className="inst-card-p">
                                Short questions or general topics may not be your best bet. Anything that cannot find clues from science publications and patent ocean.
                                </div>
                            </div>
                            <div className="inst-item">
                                <h1 className="inst-card-H">Response time:</h1>
                                <div className="inst-card-p">
                                    Typically 30 to 60 seconds
                                </div>
                            </div>
                        </div>
                        <div className="inst-top-col">
                            <div className="inst-item">
                                <h1 className="inst-card-H">Optimise your input:</h1>
                                <div className="inst-card-p">
                                    <ul className="inst-card-ul">
                                        <li>Be specific</li>
                                        <li>In the deep tech fields</li>
                                        <li>Include a ‘?’ mark for questions </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="inst-item">
                                <h1 className="inst-card-H">Input examples:</h1>
                                <div className="inst-card-p">
                                    <ul className="inst-card-ul">
                                        <li>Buprenorphine use in adolescent opiate addiction</li>
                                        <li>Best practices for designing robust optical computing hardware</li>
                                        <li>How to create an efficient k-means clustering algorithm on a Riemannian manifold? </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="inst-item">
                                <h1 className="inst-card-H">Output accuracy:</h1>
                                <div className="inst-card-p">
                                Like any AI, there's a margin for error. While we achieve a solid 99% success rate (output reasonable, logic and contextual related answers) via internal tests, we encourage you to double-check any information obtained.
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                    <div className="info" id="creditDisplay">Credits: {credits} </div>
                    <div className="tracker-142">
                    <div className="info">Tell us a bit more about what you are looking for</div>
                            <select 
                                className="tracker-96"
                                value={selectedOption1}
                                onChange={handleChange}
                            >
                                <option value="General Inquiry">General Inquiry</option>
                                <option value="Key findings">Analyse key research findings</option>
                                <option value="SWOT">SWOT analysis</option>
                                <option value="Evidenced based opinion">Find evidence based opinion</option>
                                <option value="Patent">Advanced patent analysis</option>
                            </select>
                        </div>
                    <div className="textarea-container">
                        <textarea className="inquire-textbox" placeholder="Enter your inquiry here"></textarea>
                            <div
                                className={`inquire-button_unlocked ${isRequesting ? 'inquire-button_disabled' : ''}`}
                                onClick={!isRequesting ? handleButtonClick : undefined}
                            >
                                <span className="sign-in-text">{isRequesting ? 'Processing...' : 'Inquire'}</span>
                            </div>
                    </div>    
                </div>
                {isRequesting && (
                    <div className="loader-bar-container">
                    <div className="loader-bar" style={{ width: `${progress}%` }}></div>
                    </div>
                )}
                <div className="answer-text-box"
                style={{
                    ...isDyslexiaFontEnabled && { backgroundColor: '#FFFFE0' }, // Only apply this style if isDyslexiaFontEnabled is true
                    height: answerText ? '650px' : '316px'
                }}
                >
                    <p className="answer-header">Answer:</p>
                    <p className="answer-text"
                    style={{
                        ...isDyslexiaFontEnabled && { fontFamily: '"OpenDyslexic", sans-serif' }, // Only apply this style if isDyslexiaFontEnabled is true
                    }}
                    >
                            {parse(answerText, {
                                replace: (domNode) => {
                                if (domNode.name === 'h1') {
                                    return <h1 style={customStyles.h1}>{domNode.children[0].data}</h1>;
                                }
                                if (domNode.name === 'h2') {
                                    return <h2 style={customStyles.h2}>{domNode.children[0].data}</h2>;
                                }
                                if (domNode.name === 'h3') {
                                    return <h3 style={customStyles.h3}>{domNode.children[0].data}</h3>;
                                }
                                if (domNode.name === 'p') {
                                    return <p style={customStyles.p}>{domNode.children[0].data}</p>;
                                }
                                if (domNode.name === 'br') {
                                    return <br style={customStyles.br} />;
                                }
                                // if (domNode.name === 'ul') {
                                //     return <ul style={customStyles.ul}>{domNode.children[0].data}</ul>;
                                // }
                                // if (domNode.name === 'li') {
                                //     return <li style={customStyles.li}>{domNode.children[0].data}</li>;
                                // }
                                }
                            })}
                    </p>
                </div>
            </div>
            <div className="support-frame-162">
                <div className="support-frame-126">
                    <p className="support-text-blue">Support Evidence</p>
                    <p className="mini-grey-text">({itemCount.length} Items Found)</p>
                    <div className="dropdown-box">
                        <select className="inquire-text-1" onChange={handleFilterChange}>
                            <option value="all">All</option>
                            <option value="top10">Top 10</option>
                            <option value="top20">Top 20</option>
                        </select>
                    </div>
                    {/* <p className="support-text-blue">Sort By</p>
                    <div className="dropdown-box">
                        <p className="inquire-text-1">
                            Year
                        </p>
                    </div> */}

                    <label>
                        <input
                            type="checkbox"
                            checked={isDyslexiaFontEnabled}
                            onChange={handleDyslexiaFontToggle}
                        />
                        Use Dyslexia-Friendly Font
                    </label>

                </div>
                <div className="support-frame-107">
                    <div className="support-frame-95a">
                        <div className="support-frame-135">
                            <p className="support-text">Support evidence</p>
                        </div>
                        {supportEvidence.map((item, index) => (
                            <div key={index} className={`support-text-content ${item.className}`}>
                                <span className="evidence-number">{index + 1}. </span>{item.content}
                            </div>
                        ))}    
                    </div>
                    <div className="support-frame-95b">
                        <div className="support-frame-135">
                            <p className="support-text">Reference & sources</p>
                        </div>
                        {referenceSources.map((item, index) => (
                            <div key={index} className={`support-text-content ${item.className}`}>
                                <a href={item.url} target="_blank" rel="noopener noreferrer">
                                    {item.title} - {formatUrlDisplayName(item.url)}
                                </a>
                            </div>
                        ))}
                    </div>
                    <div className="support-frame-95c">
                        <div className="support-frame-135">
                            <p className="support-text">Relevance </p>
                        </div>
                        {relevanceDisplay.map((item, index) => (
                            <div key={index} className={`${item.className} relevance-circle`} style={item.style}></div>
                        ))}
                      
                    </div>
                </div>
            </div>
            
          </div>
          <div className="comment">
            <div className="comment-container">
                <div className="comment-text">Leave a comment</div>
            </div>
            <div className="message-frame">
                <div className="message-header">Message *</div>
                <textarea 
                    className="message-box" 
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type your message here..."
                />
            </div>
            <div className={buttonColor} onClick={handleSubmit}>
                <div className="feature-text-active">{buttonText}</div>
            </div>
            <div className="contact-text-combined">
                Or reach out to us at  
                <a href="mailto:contact@tylo.ai" className="contact-email"> contact@tylo.ai</a>
            </div>

          </div>
          <div className="all-container">
                <div className="all-rights-text">2024 Tylo AI Ltd. All rights reserved. </div>   
                <div className="all-rights-highlight" onClick={handleTOUClick}>
                    Terms of use  
                </div>
                <div className="all-rights-highlight" onClick={handlePrivacyPolicyClick}>
                    Privacy Policy 
                </div> 
                <a href="https://www.linkedin.com/company/tyloai/" target="_blank" rel="noopener noreferrer">
                <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="https://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_2801_871)">
    <path d="M29.4609 0.75H9.53906C4.685 0.75 0.75 4.685 0.75 9.53906V29.4609C0.75 34.315 4.685 38.25 9.53906 38.25H29.4609C34.315 38.25 38.25 34.315 38.25 29.4609V9.53906C38.25 4.685 34.315 0.75 29.4609 0.75Z" fill="white"/>
    <path d="M29.4609 0.75H9.53906C4.685 0.75 0.75 4.685 0.75 9.53906V29.4609C0.75 34.315 4.685 38.25 9.53906 38.25H29.4609C34.315 38.25 38.25 34.315 38.25 29.4609V9.53906C38.25 4.685 34.315 0.75 29.4609 0.75Z" fill="#4476F1"/>
    <path d="M27.8079 32.6375H32.0955C32.2508 32.6375 32.3998 32.5757 32.5097 32.4659C32.6196 32.356 32.6814 32.207 32.6814 32.0517L32.6836 22.9928C32.6836 18.258 31.6633 14.6186 26.1302 14.6186C24.0268 14.5403 22.0433 15.6246 20.9729 17.4352C20.9677 17.444 20.9597 17.4508 20.9502 17.4546C20.9407 17.4585 20.9303 17.4591 20.9204 17.4564C20.9105 17.4538 20.9018 17.4479 20.8955 17.4398C20.8893 17.4317 20.8858 17.4218 20.8857 17.4116V15.6416C20.8857 15.4862 20.824 15.3372 20.7141 15.2273C20.6042 15.1174 20.4552 15.0557 20.2998 15.0557H16.2309C16.0755 15.0557 15.9265 15.1174 15.8166 15.2273C15.7067 15.3372 15.645 15.4862 15.645 15.6416V32.0508C15.645 32.2062 15.7067 32.3552 15.8166 32.4651C15.9265 32.575 16.0755 32.6367 16.2309 32.6367H20.5182C20.6736 32.6367 20.8226 32.575 20.9325 32.4651C21.0424 32.3552 21.1042 32.2062 21.1042 32.0508V23.9395C21.1042 21.646 21.5392 19.4249 24.3826 19.4249C27.1856 19.4249 27.2219 22.0493 27.2219 24.088V32.0515C27.2219 32.2069 27.2837 32.3559 27.3935 32.4658C27.5034 32.5757 27.6525 32.6375 27.8079 32.6375ZM6.31641 9.48457C6.31641 11.2225 7.74712 12.6524 9.48516 12.6524C11.2228 12.6523 12.6526 11.2214 12.6526 9.48384C12.6523 7.74624 11.2223 6.31641 9.48457 6.31641C7.74639 6.31641 6.31641 7.74668 6.31641 9.48457ZM7.33579 32.6375H11.6288C11.7842 32.6375 11.9332 32.5757 12.0431 32.4658C12.153 32.3559 12.2147 32.2069 12.2147 32.0515V15.6416C12.2147 15.4862 12.153 15.3372 12.0431 15.2273C11.9332 15.1174 11.7842 15.0557 11.6288 15.0557H7.33579C7.18039 15.0557 7.03136 15.1174 6.92147 15.2273C6.81159 15.3372 6.74985 15.4862 6.74985 15.6416V32.0515C6.74985 32.2069 6.81159 32.3559 6.92147 32.4658C7.03136 32.5757 7.18039 32.6375 7.33579 32.6375Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_2801_871">
      <rect width="37.5" height="37.5" fill="white" transform="translate(0.75 0.75)"/>
    </clipPath>
  </defs>
</svg>
</a>
            </div>
        
        </div>
    );
}

export default Inquire;